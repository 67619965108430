import { ajax } from "@common/services/ajax";
import { AcquisitionCampaignUrls } from "@portal-enroll/api/acquisitionCampaignApiUrls";

export const acquisitionCampaignApi = {
  retrieve: (slug: string) =>
    ajax.get<{
      phone: string;
      slug: string;
    }>(AcquisitionCampaignUrls.retrieveBySlug(slug)),
};
