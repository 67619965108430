import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import { useShowI18nKeysAtom } from "@portal-shared/hooks/useShowI18nKeysAtom";
import enJson from "@portal-shared/translations/en-base.json";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";

type EnJsonTypes = typeof enJson;

export type TranslationKey = keyof EnJsonTypes;

type FormatMessageType = ReturnType<typeof useIntl>["formatMessage"];
type TArgs = Parameters<FormatMessageType>;
type TValues = TArgs[1];

function getT(formatMessage: FormatMessageType, showI18nKeys: boolean = false) {
  function t(id: TranslationKey): string {
    if (!isProduction() && showI18nKeys) {
      return id;
    }
    return formatMessage({ id });
  }

  return t;
}

function getTJsx(
  formatMessage: FormatMessageType,
  showI18nKeys: boolean = false
) {
  function tJsx(id: TranslationKey, values: TValues): ReactNode {
    if (!isProduction() && showI18nKeys) {
      return id;
    }
    return formatMessage({ id }, values) as ReactNode;
  }

  return tJsx;
}

export const useRhIntl = () => {
  const { formatMessage } = useIntl();
  const [showI18nKeys] = useShowI18nKeysAtom();
  const [tFunctions, setTFunctions] = useState({
    t: getT(formatMessage, showI18nKeys),
    tJsx: getTJsx(formatMessage, showI18nKeys),
  });

  useEffect(() => {
    setTFunctions({
      t: getT(formatMessage, showI18nKeys),
      tJsx: getTJsx(formatMessage, showI18nKeys),
    });
  }, [formatMessage, showI18nKeys]);

  return tFunctions;
};
