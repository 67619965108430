import { ProductTier } from "@portal-shared/models/ProductTier.model";
import { portalApi } from "@portal/api/api";
import { ProductQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProductTiers = () => {
  return useQuery({
    queryFn: () => portalApi.products.tiers.list(),
    queryKey: ProductQueryKeys.tiers.list(),
    select: (data) => {
      return data.map((tier) => new ProductTier(tier));
    },
    structuralSharing: false,
  });
};
