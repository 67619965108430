import { formatKwh } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { RhSlider } from "@design-system/components/RhSlider/RhSlider";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  SliderOnChangeCommittedFunction,
  SliderOnChangeFunction,
} from "@design-system/types/domEventTypes";
import { Tooltip } from "@mui/material";
import { monthlyUsageSliderTranslations } from "@portal-enroll/components/MonthlyUsageSlider/MonthlyUsageSlider.en.i18n";
import {
  StyledLabelText,
  StyledPopper,
} from "@portal-enroll/components/MonthlyUsageSlider/MonthlyUsageSliderInput.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface SliderLabelProps {
  children: JSX.Element;
  open: boolean;
  value: number;
}
const SliderLabel = ({ open, value, children }: SliderLabelProps) => {
  const { translate } = useTranslations();

  const { tMonthlyUsageSliderAverageHomeUses } = translate(
    monthlyUsageSliderTranslations
  );

  const label = (
    <>
      {formatKwh(value, 0)}
      {value === 1000 && (
        <StyledLabelText
          component="div"
          variant="caption"
          fontWeight={FontWeight.Regular}
        >
          {tMonthlyUsageSliderAverageHomeUses}
        </StyledLabelText>
      )}
    </>
  );

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="bottom-start"
      title={label}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              enabled: false,
              name: "flip",
            },
          ],
        },
      }}
      components={{
        Popper: StyledPopper,
      }}
    >
      {children}
    </Tooltip>
  );
};

const RANGE_START = 500;
const RANGE_END = 3500;
const RANGE_STEP = 500;

interface MonthlyUsageSliderInputProps {
  onChange?: SliderOnChangeFunction;
  onChangeCommitted?: SliderOnChangeCommittedFunction;
  value: number;
}
export const MonthlyUsageSliderInput = ({
  onChange = noop,
  onChangeCommitted,
  value,
}: MonthlyUsageSliderInputProps) => {
  const { translate } = useTranslations();

  const { tMonthlyUsageSliderLabel } = translate(
    monthlyUsageSliderTranslations
  );

  return (
    <RhSlider
      aria-label={tMonthlyUsageSliderLabel}
      value={value}
      min={RANGE_START}
      max={RANGE_END}
      step={RANGE_STEP}
      marks
      valueLabelDisplay="on"
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      components={{
        ValueLabel: SliderLabel,
      }}
    />
  );
};
