import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { signUpStepPath } from "@enroll-utils/constants/routePaths";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import {
  SignUpStateType,
  setSignUpInfo,
} from "@portal-enroll/slices/signUpSlice";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal-shared/constants/offer.constant";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface HandleEnrollNowClickFnArgs {
  estimatedMonthlyUsage: number | null;
  offerSnapshot: OfferSnapshot;
}

export const useHandleEnrollNowClick = () => {
  const navigate = useNavigate();
  const { featureFlagClient } = useFeatureFlagClient();

  const { serviceAddress } = useSelector(selectSignUpState);
  const dispatch = useDispatch();

  const prospect = useProspectFromContext();

  const handleEnrollNowClick = useCallback(
    (props: HandleEnrollNowClickFnArgs) => {
      const { offerSnapshot, estimatedMonthlyUsage } = props;

      const signUpData: Partial<SignUpStateType> = {
        campaignSlug: offerSnapshot.campaignSlug,
        dunsNumber: offerSnapshot.dunsNumber,
        estimatedMonthlyUsage:
          estimatedMonthlyUsage || DEFAULT_AVERAGE_MONTHLY_USAGE,
        offerSnapshotId: offerSnapshot.id,
        offerSnapshotUuid: offerSnapshot.uuid,
        visitedSteps: ["name"],
      };

      dispatch(setSignUpInfo(signUpData));

      featureFlagClient.trackMetric(
        FeatureFlagMetric.PortalProspectSelectedPlan
      );

      navigate(signUpStepPath("name"));
    },
    [dispatch, featureFlagClient, navigate, prospect.zipCode, serviceAddress]
  );

  return handleEnrollNowClick;
};
