import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { LOGGED_OUT_FIELD_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import styled from "styled-components";

export const StyledLoggedOutPageHeaderContainer = styled.div<{
  $maxWidth?: number;
}>`
  margin: 0 auto;
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "60ch")};
  padding: 0 2rem;
  width: 100%;
`;

export const StyledIllustrationContainer = styled.div`
  margin: 0 auto ${rhSpacingPx(3)};
  width: 90px;
`;

export const StyledSubheaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
`;

export const StyledIconContainer = styled.div`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  display: none;
  margin-right: ${rhSpacingPx(3 / 2)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    display: block;
  }
`;

export const StyledSubtitleContainer = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_500};
  }
`;
