export const monthlyUsageSliderTranslations = {
  tMonthlyUsageSliderAverageHomeUses:
    "The average home in Texas uses 1,176 KWh per month",
  tMonthlyUsageSliderHomeDescriptor1000:
    "I live in a 2-3 bedroom home (~1,500 sqft)",
  tMonthlyUsageSliderHomeDescriptor1500:
    "I live in a 2-3 bedroom home (~2,000 sqft)",
  tMonthlyUsageSliderHomeDescriptor2000:
    "I live in a 3-4 bedroom home (~2,500 sqft)",
  tMonthlyUsageSliderHomeDescriptor2500:
    "I live in a 3-4 bedroom home (~3,500 sqft)",
  tMonthlyUsageSliderHomeDescriptor3000:
    "I live in a 4+ bedroom home (~4,000 sqft)",
  tMonthlyUsageSliderHomeDescriptor3500:
    "I live in a 4+ bedroom home (~4,500 sqft)",
  tMonthlyUsageSliderHomeDescriptor500:
    "I live in an apartment or condo (~750 sqft)",
  tMonthlyUsageSliderHomePrefDescriptor1000:
    "I like my home cool in the summer and warm in the winter",
  tMonthlyUsageSliderHomePrefDescriptor1500:
    "I like my home really cold in the summer and really warm in the winter",
  tMonthlyUsageSliderHomePrefDescriptor2000:
    "I like my home cool in the summer and warm in the winter",
  tMonthlyUsageSliderHomePrefDescriptor2500:
    "I like my home really cold in the summer and really warm in the winter",
  tMonthlyUsageSliderHomePrefDescriptor3000:
    "I like my home cool in the summer and warm in the winter",
  tMonthlyUsageSliderHomePrefDescriptor3500:
    "I like my home really cold in the summer and really warm in the winter",
  tMonthlyUsageSliderHomePrefDescriptor500:
    "I like my home cool in the summer and warm in the winter",
  tMonthlyUsageSliderLabel: "Slider to choose monthly usage",
  tMonthlyUsageSliderPriceBasedOnEstimated: "Price based on estimated usage of",
};
