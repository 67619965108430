import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { guestAddBankAccountTranslations } from "@portal-guest/components/GuestAddBankAccount/GuestAddBankAccount.en.i18n";
import {
  StyledCardContent,
  StyledRhInnerCard,
  StyledTitle,
} from "@portal-guest/components/GuestAddBankAccount/GuestAddBankAccount.styled";
import { LoggedOutZuoraAddBankAccountForm } from "@portal-shared/components/LoggedOutZuoraAddBankAccountForm/LoggedOutZuoraAddBankAccountForm";
import {
  PaymentMethodOptions,
  PaymentMethodOptionsType,
} from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useRef, useState } from "react";

interface GuestAddBankAccountProps {
  onBankAccountAdded(
    response: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ): void;
  showFlashSuccess?: boolean;
  showPaymentOptions?: boolean;
}

export const GuestAddBankAccount = ({
  onBankAccountAdded,
  showFlashSuccess = true,
  showPaymentOptions,
}: GuestAddBankAccountProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { translate } = useTranslations();

  const {
    tGuestAddBankAccountAriaCloseLabel,
    tGuestAddBankAccountAriaLabel,
    tGuestAddBankAccountNewBankAccount,
    tGuestAddBankAccountNewAccountAddedSuccessfully,
    tGuestAddBankAccountTitle,
  } = translate(guestAddBankAccountTranslations);
  const flash = useRhFlash();

  const paymentOptionsRef = useRef<PaymentMethodOptionsType>({
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  });

  return (
    <>
      <StyledRhInnerCard onClick={() => setIsModalOpen(true)}>
        <StyledCardContent>
          <RhTypography variant="subtitle2" fontWeight={FontWeight.Semibold}>
            {tGuestAddBankAccountNewBankAccount}
          </RhTypography>
          <RightChevron />
        </StyledCardContent>
      </StyledRhInnerCard>
      {isModalOpen ? (
        <RhModal
          ariaLabel={tGuestAddBankAccountAriaLabel}
          ariaCloseLabel={tGuestAddBankAccountAriaCloseLabel}
          handleClose={() => setIsModalOpen(false)}
        >
          <StyledTitle>{tGuestAddBankAccountTitle}</StyledTitle>
          <LoggedOutZuoraAddBankAccountForm
            onSuccess={(response) => {
              onBankAccountAdded(response, paymentOptionsRef.current);
              setIsModalOpen(false);
              if (showFlashSuccess) {
                flash.success(tGuestAddBankAccountNewAccountAddedSuccessfully);
              }
            }}
            onFailure={(errorMessage) => {
              setIsModalOpen(false);
              flash.error(errorMessage);
            }}
            paymentOptions={paymentOptionsRef.current}
          />
          <PaymentMethodOptions
            showPaymentOptions={showPaymentOptions}
            paymentOptionsRef={paymentOptionsRef.current}
          />
        </RhModal>
      ) : null}
    </>
  );
};
