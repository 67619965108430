import { UUIDType } from "@common/types/apiTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import { formatCurrency } from "@common/utils/dataFormatters";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import {
  ProductPriceFrequency,
  ProductPriceFrequencyPrettyNames,
  ProductPriceFrequencyPrettyNamesMobile,
  ProductPriceTermPeriod,
  ProductPriceTermPeriodPrettyNames,
  ProductPriceType,
} from "@portal-shared/types/productTypes";

export class ProductPrice {
  frequency: ProductPriceFrequency;
  id: UUIDType;
  rate: string;
  termLength: string;
  termPeriod: ProductPriceTermPeriod;
  tier: ProductTier;

  constructor(tier: ProductTier, price: ProductPriceType) {
    this.frequency = price.frequency;
    this.id = price.id;
    this.rate = price.rate;
    this.termLength = price.termLength;
    this.termPeriod = price.termPeriod;
    this.tier = tier;
  }

  get formattedTermLength() {
    const prettyPeriod = ProductPriceTermPeriodPrettyNames[this.termPeriod];

    return `${this.termLength} ${prettyPeriod}`;
  }

  get formattedPricePerFrequency() {
    const frequencyPrettyName =
      ProductPriceFrequencyPrettyNames[this.frequency];

    return `${formatCurrency(this.rate)} / ${frequencyPrettyName}`;
  }

  get mobileFormattedPricePerFrequency() {
    const frequencyPrettyName =
      ProductPriceFrequencyPrettyNamesMobile[this.frequency];

    return `${formatCurrency(this.rate)} / ${frequencyPrettyName}`;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<
  ProductPriceType,
  ProductPrice
> = undefined;
