import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { SignUpEnrollmentFormValues } from "@portal-enroll/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";
import { isRequired } from "@portal-shared/forms/validators";

export const signUpEnrollmentFormValidations = {
  enrollmentType: [isRequired],
  serviceStartDate: [isRequired],
};

export const signUpEnrollmentFormValidator =
  generateValidationErrorCollector<SignUpEnrollmentFormValues>(
    signUpEnrollmentFormValidations
  );
