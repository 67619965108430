import { UUIDType } from "@common/types/apiTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { ProductTierGrid } from "@portal-shared/components/ProductTierGrid/ProductTierGrid";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { AddOnsOptInBackButton } from "@portal/components/ProductAddOnsOptIn/AddOnsOptInBackButton/AddOnsOptInBackButton";
import { useProductTiers } from "@portal/hooks/queries/useProductTiers";
import { customerHomePath } from "@portal/routes/routePaths";
import {
  productAddOnsSlice,
  selectProductAddOns,
} from "@portal/slices/productAddOnSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  gap: 24px;
  padding-top: 32px;
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-basis: 90%;
  }
  @media (max-width: ${RhythmBreakpoints.MD}px) {
    flex-basis: 80%;
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
  text-align: center;
  white-space: pre-line;
`;

export const ProductSelection = () => {
  const { t } = useRhIntl();

  const dispatch = useDispatch();

  const location = useLocation();

  // clear priceIds unless we arrive from confirmation backtrack
  useEffect(() => {
    if (!location.state?.retainPrices) {
      dispatch(productAddOnsSlice.actions.clearProductPrices());
    }
  }, []);

  const { selectedProductPriceIds } = useSelector(selectProductAddOns);

  const navigate = useNavigate();

  const goToConfirm = () => {
    navigate("confirmation");
  };

  const productTiersQuery = useProductTiers();

  if (productTiersQuery.isError) {
    return <PortalPageLayout>{t("ProductSelection.error")}</PortalPageLayout>;
  }

  if (productTiersQuery.isPending) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />
      </PortalPageLayout>
    );
  }

  if (productTiersQuery.data.length === 0) {
    return (
      <PortalPageLayout>{t("ProductSelection.noneAvailable")}</PortalPageLayout>
    );
  }

  const handleTierCardClick = (priceId: UUIDType) => {
    if (selectedProductPriceIds.includes(priceId)) {
      dispatch(productAddOnsSlice.actions.removeProductPrice(priceId));
    } else {
      dispatch(productAddOnsSlice.actions.addProductPrice(priceId));
    }
  };

  const continueDisabled = selectedProductPriceIds.length <= 0;

  const backClickHandler = () => {
    dispatch(productAddOnsSlice.actions.clearProductPrices());
    navigate(customerHomePath());
  };

  return (
    <PortalPageLayout>
      <CenterWrapper>
        <StyledContainer>
          <HeaderContainer>
            <RhTypography variant="h1">
              {t("ProductSelection.title")}
            </RhTypography>
            <RhTypography variant="subtitle1" color="textSecondary">
              {t("ProductSelection.subTitle")}
            </RhTypography>
          </HeaderContainer>

          <ProductTierGrid
            productTiers={productTiersQuery.data}
            onTierSelected={handleTierCardClick}
            selectedProductPriceIds={selectedProductPriceIds}
          />

          <RhButton
            data-tracking-click={{
              event: "Customer continuing to Product addons review step",
            }}
            disabled={continueDisabled}
            onClick={goToConfirm}
            color="primary"
          >
            {t("ProductSelection.continueButton")}
          </RhButton>
          <AddOnsOptInBackButton onClick={backClickHandler} />
        </StyledContainer>
      </CenterWrapper>
    </PortalPageLayout>
  );
};
