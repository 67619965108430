import {
  enrollBasePath,
  signUpBasePath,
} from "@enroll-utils/constants/routePaths";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { setSignUpInfo } from "@portal-enroll/slices/signUpSlice";
import { useFingerPrintId } from "@portal-shared/hooks/useFingerPrintId";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const useFingerPrintJS = () => {
  const { pathname } = useLocation();
  const { prospectId } = useSelector(selectSignUpState);
  const dispatch = useDispatch();
  const fingerprintId = useFingerPrintId();

  const isInSignUp =
    pathname.includes(signUpBasePath()) || pathname.includes(enrollBasePath());

  useEffect(() => {
    if (prospectId && fingerprintId && isInSignUp) {
      dispatch(setSignUpInfo({ fingerprintId }));
    }
  }, [dispatch, fingerprintId, isInSignUp, prospectId]);

  return null;
};
