import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { signUpStepPath } from "@enroll-utils/constants/routePaths";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { DepositForm } from "@portal-enroll/pages/SignUpDepositRequiredPage/DepositForm";
import { DepositSelectionForm } from "@portal-enroll/pages/SignUpDepositRequiredPage/DepositSelectionForm";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const SignUpDepositRequiredPage = () => {
  const { creditEvaluation, depositAmount, depositAlternativeAmount } =
    useSelector(selectSignUpState);

  const { featureFlagClient } = useFeatureFlagClient();
  const navigate = useNavigate();

  const { portalDepositAlternativeProgram } = featureFlagClient.getFlags([
    ["portalDepositAlternativeProgram", false],
  ]);

  if (creditEvaluation === CreditScoreOutcome.manualReview) {
    navigate(signUpStepPath("call-us"));
  }

  const showDepositSelectionForm =
    depositAlternativeAmount &&
    depositAlternativeAmount !== "0" &&
    portalDepositAlternativeProgram.value;

  return (
    <SignUpPageLayout>
      {showDepositSelectionForm ? (
        <DepositSelectionForm
          depositAmount={depositAmount}
          depositAlternativeAmount={depositAlternativeAmount}
        />
      ) : (
        <DepositForm depositAmount={depositAmount} />
      )}
    </SignUpPageLayout>
  );
};
