import styled from "styled-components";

export const ProductTierFeaturesListItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  & > svg {
    height: 24px;
    width: 24px;
  }
`;

export const ProductTierFeaturesUnorderedList = styled.ul`
  border-top: var(--grey-border);
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: armenian;
  padding-top: 16px;
`;
