import { RhIconButton } from "@design-system/components/RhIconButton/RhIconButton";
import { ReactComponent as LeftChevron } from "@design-system/icons/LeftChevron.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { SvgIcon, useMediaQuery, useTheme } from "@mui/material";
import { StyledTabletDownButton } from "@portal-enroll/components/SignUpBackButton/SignUpBackButton.styled";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { ActionType } from "@portal-enroll/services/segment.service";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

interface SignUpBackButtonProps {
  handleClick?: () => void;
}

export const SignUpBackButton = ({ handleClick }: SignUpBackButtonProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(
    theme.breakpoints.down(RhythmBreakpoints.SM)
  );
  const navigate = useNavigate();
  const { currentStep, trackEvent } = useSignUpFlow();

  const backButtonCTA = formatMessage({ id: "signUpBackButton.backCTA" });

  const clickHandler = () => {
    trackEvent({
      action: ActionType.clickedBackPage,
      label: `${currentStep}BackPage`,
    });

    if (handleClick) {
      handleClick();
    } else {
      navigate(-1);
    }
  };

  const icon = (
    <SvgIcon
      viewBox="5 0 47 90"
      fill="none"
      component={LeftChevron}
      title={backButtonCTA}
      fontSize="inherit"
      style={{
        fontSize: isTabletDown ? "0.9rem" : "inherit",
        transform: "translateY(-1px)",
      }}
    />
  );

  return isTabletDown ? (
    <StyledTabletDownButton
      data-tracking-click={{ event: "Prospect going back to previous step" }}
      aria-label={backButtonCTA}
      role="link"
      color="primary"
      variant="text"
      onClick={clickHandler}
      startIcon={icon}
    >
      {backButtonCTA}
    </StyledTabletDownButton>
  ) : (
    <RhIconButton aria-label={backButtonCTA} role="link" onClick={clickHandler}>
      {icon}
    </RhIconButton>
  );
};
