import { CSSClasses } from "@design-system/components/CssReset/CssReset";
import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import { ReactComponent as ShoppingCart } from "@design-system/icons/ShoppingCart.svg";
import {
  CartToggleButton,
  CartToggleButtonText,
  CartToggleButtonTextLabel,
  CartToggleButtonTextTitle,
  CartToggleContainer,
  CartToggleIcon,
  StyledBadge,
} from "@portal-enroll/components/CartToggle/CartToggle.styled";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { ActionType } from "@portal-enroll/services/segment.service";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React, { useEffect, useState } from "react";

export interface CartToggleProps {
  cartOpen: boolean;
  chevron?: JSX.Element;
  handleClick: () => void;
  invisibleBadgeTimeout?: number;
  isMobileCart?: boolean;
  itemCount: number;
  offerSnapshot: OfferSnapshot | null;
}

export const CartToggle = ({
  handleClick,
  offerSnapshot,
  cartOpen,
  invisibleBadgeTimeout = 750,
  itemCount,
  isMobileCart = false,
  chevron = <ChevronDown />,
}: CartToggleProps) => {
  const { t } = useRhIntl();
  const { trackEvent } = useSignUpFlow();
  const [invisibleBadge, setInvisibleBadge] = useState(true);

  useEffect(() => {
    const animateBadgeOnDelay = setTimeout(() => {
      if (invisibleBadge) {
        setInvisibleBadge(false);
      }
    }, invisibleBadgeTimeout);

    return () => clearTimeout(animateBadgeOnDelay);
  }, [invisibleBadge, invisibleBadgeTimeout]);

  const handleCartToggle = () => {
    if (!cartOpen) {
      trackEvent({
        action: ActionType.openedCart,
        cartPlanId: offerSnapshot?.id,
        cartPlanPrice: Number(offerSnapshot?.price),
        cartPlanTerms: offerSnapshot?.termMonths,
        cartPlanTitle: offerSnapshot?.title,
      });
    }

    // Set in /globalStyles.ts which is a styled-components createGlobalStyles wrapper
    // Want to prevent the double scroll when the mobile cart is open
    document.body.classList.toggle(CSSClasses.OverflowHidden);

    handleClick();
  };

  return offerSnapshot ? (
    <CartToggleContainer $isMobileCart={isMobileCart}>
      <CartToggleButton
        onClick={handleCartToggle}
        aria-label={t("CartToggle.toggleCartLabel")}
      >
        <StyledBadge badgeContent={itemCount} invisible={invisibleBadge}>
          <ShoppingCart />
        </StyledBadge>
        <CartToggleButtonText>
          <CartToggleButtonTextLabel>
            {t("CartToggle.selectedPlanLabel")}
          </CartToggleButtonTextLabel>
          <CartToggleButtonTextTitle>
            {offerSnapshot.title}
          </CartToggleButtonTextTitle>
        </CartToggleButtonText>
        <CartToggleIcon $open={cartOpen}>{chevron}</CartToggleIcon>
      </CartToggleButton>
    </CartToggleContainer>
  ) : null;
};
