import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshotCard } from "@portal-enroll/components/OfferSnapshotCard/OfferSnapshotCard";
import { OfferSnapshotCardSkeleton } from "@portal-enroll/components/OfferSnapshotCard/OfferSnapshotCard.skeleton";
import { OfferSnapshotFilterTabs } from "@portal-enroll/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs";
import { selectedOfferSnapshotFilterAtom } from "@portal-enroll/components/OfferSnapshotFilterTabs/selectedOfferSnapshotFilter.atom";
import { PlansPageEmptyState } from "@portal-enroll/components/PlansPageEmptyState/PlansPageEmptyState";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { useHandleEnrollNowClick } from "@portal-enroll/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { enrollOffersPageOfferSnapshotCardsTranslations } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageOfferSnapshotCards/EnrollOffersPageOfferSnapshotCards.en.i18n";
import { OfferSnapshotCardContainer } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageOfferSnapshotCards/EnrollOffersPageOfferSnapshotCards.styled";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { useCompetitorPlanOfferSnapshotUuidAtom } from "@portal-shared/components/CompetitorPlanComparison/useCompetitorPlanOfferSnapshotUuidAtom";
import { useCompetitorPlanTermMonthsAtom } from "@portal-shared/components/CompetitorPlanComparison/useCompetitorPlanTermMonthsAtom";
import { useSharedModals } from "@portal-shared/components/SharedModalsManager/useSharedModals";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { useAtomValue } from "jotai";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

interface EnrollOffersPageOfferSnapshotCardsProps {
  billCalculatorId: string;
  competitorComparisonId: string;
  estimatedMonthlyUsage: number;
  isPending: boolean;
  offerSnapshots: OfferSnapshot[];
  showBillCostEstimator: boolean;
  showCompetitorComparison: boolean;
}

const determineViewableOfferSnapshots = ({
  offerSnapshots,
  filter,
  showFilter,
}: {
  filter: string;
  offerSnapshots: OfferSnapshot[];
  showFilter: boolean;
}) => {
  if (filter === "all" || !showFilter) {
    return offerSnapshots;
  }

  if (filter === "featured") {
    return offerSnapshots.filter(
      (offerSnapshot) => new OfferSnapshot(offerSnapshot).isFeatured
    );
  }

  return offerSnapshots.filter(
    (offerSnapshot) => offerSnapshot.termMonths === Number(filter)
  );
};

export const EnrollOffersPageOfferSnapshotCards = (
  props: EnrollOffersPageOfferSnapshotCardsProps
) => {
  const {
    estimatedMonthlyUsage,
    isPending,
    offerSnapshots,
    billCalculatorId,
    competitorComparisonId,
    showBillCostEstimator,
    showCompetitorComparison,
  } = props;

  const { addModal } = useSharedModals();
  const { translate } = useTranslations();
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const prospect = useProspectFromContext();

  const [, setCompetitorPlanOfferSnapshotUuidAtom] =
    useCompetitorPlanOfferSnapshotUuidAtom();

  const [, setCompetitorPlanTermMonthsAtom] = useCompetitorPlanTermMonthsAtom();

  const campaignSlug = prospect.rcid ?? RhythmCampaignSlugs.Default;
  const { acquisition } = useSelector(selectSignUpState);

  const selectedOfferSnapshotFilter = useAtomValue(
    selectedOfferSnapshotFilterAtom
  );

  const { tEnrollOffersPageOfferSnapshotCardsSelectPlanCta } = translate(
    enrollOffersPageOfferSnapshotCardsTranslations
  );
  const acquisitionTfn =
    acquisition.acquisitionTfn || brandInformation.phoneNumbersGeneralSales;

  const handleSelectPlanClick = useCallback(
    (offerSnapshot: OfferSnapshot) => {
      if (offerSnapshot.isThermostatOffer) {
        return addModal({
          offerSnapshotThermostatRequired: {
            offerSnapshot,
            onEnrollClick: () =>
              handleEnrollNowClick({
                estimatedMonthlyUsage,
                offerSnapshot,
              }),
          },
        });
      }

      return handleEnrollNowClick({
        estimatedMonthlyUsage,
        offerSnapshot,
      });
    },
    [estimatedMonthlyUsage, handleEnrollNowClick, addModal]
  );

  if (isPending) {
    return (
      <OfferSnapshotCardContainer>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <OfferSnapshotCardSkeleton key={index} />
        ))}
      </OfferSnapshotCardContainer>
    );
  }

  const noOfferSnapshots = !isPending && offerSnapshots.length === 0;

  if (noOfferSnapshots) {
    return (
      <OfferSnapshotCardContainer>
        <PlansPageEmptyState />
      </OfferSnapshotCardContainer>
    );
  }

  const handleCalculateBillClick = () => {
    document
      ?.querySelector(`#${CSS.escape(billCalculatorId)}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const handleCompareRatesClick = (offerSnapshot: OfferSnapshot) => {
    setCompetitorPlanTermMonthsAtom(offerSnapshot.termMonths.toString());
    setCompetitorPlanOfferSnapshotUuidAtom(offerSnapshot.uuid);

    document
      ?.querySelector(`#${CSS.escape(competitorComparisonId)}`)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const showFilter =
    !campaignSlug || campaignSlug === RhythmCampaignSlugs.Default;

  const filteredOfferSnapshots = determineViewableOfferSnapshots({
    filter: selectedOfferSnapshotFilter,
    offerSnapshots,
    showFilter,
  });

  return (
    <>
      <OfferSnapshotFilterTabs offerSnapshots={offerSnapshots} />

      <OfferSnapshotCardContainer>
        {filteredOfferSnapshots.map((offerSnapshot) => {
          const handleLearnMoreClick = () => {
            addModal({
              offerSnapshotLearnMore: {
                offerSnapshotUUID: offerSnapshot.uuid,
                onEnrollClick: () =>
                  handleEnrollNowClick({
                    estimatedMonthlyUsage,
                    offerSnapshot,
                  }),
                selectPlanCtaText:
                  tEnrollOffersPageOfferSnapshotCardsSelectPlanCta,
              },
            });
          };

          return (
            <OfferSnapshotCard
              key={offerSnapshot.id}
              offerSnapshot={offerSnapshot}
              onEnrollClick={() => handleSelectPlanClick(offerSnapshot)}
              onCompareRatesClick={
                showCompetitorComparison && !offerSnapshot.solarEligible
                  ? handleCompareRatesClick
                  : undefined
              }
              phoneNumber={acquisitionTfn}
              onLearnMoreClick={handleLearnMoreClick}
              onCalculateBillClick={
                showBillCostEstimator && !offerSnapshot.solarEligible
                  ? handleCalculateBillClick
                  : undefined
              }
            />
          );
        })}
      </OfferSnapshotCardContainer>
    </>
  );
};
