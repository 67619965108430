import {
  RhSubmitButton,
  RhSubmitButtonProps,
} from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { StyledLoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm.styled";
import { PortalSubmitButton } from "@portal-shared/components/PortalSubmitButton/PortalSubmitButton";
import React, { PropsWithChildren } from "react";

interface SignUpFormPropsType {
  onChange?: (event: React.FormEvent<HTMLFormElement>) => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  showProgress?: boolean;
  submitButtonDisabled?: boolean;
  submitButtonText: string;
}

export const LoggedOutForm = ({
  submitButtonText,
  showProgress,
  children,
  ...formProps
}: PropsWithChildren<SignUpFormPropsType>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const submitButtonProps: PropsWithChildren<RhSubmitButtonProps> = {
    children: submitButtonText,
    "data-tracking-click": {
      event: "Prospect advancing to next step in enrollment flow",
    },
    fullWidth: true,
    size: isMobile ? "medium" : "large",
  };

  return (
    <StyledLoggedOutForm noValidate {...formProps}>
      {children}

      {showProgress ? (
        <PortalSubmitButton {...submitButtonProps} />
      ) : (
        <RhSubmitButton {...submitButtonProps} />
      )}
    </StyledLoggedOutForm>
  );
};
