import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { determineCreditEvaluationDefinition } from "@common/services/creditEvaluation.service";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useFeatureFlagIdentifyCredit = () => {
  const { creditEvaluation, depositAmount, ssnRequired } =
    useSelector(selectSignUpState);

  const {
    featureFlagClient: { sendIdentifyEvent },
  } = useFeatureFlagClient();

  useEffect(() => {
    if (creditEvaluation) {
      sendIdentifyEvent({
        creditEvaluation: determineCreditEvaluationDefinition({
          creditEvaluation,
          depositAmount,
          ssnRequired,
        }),
      });
    }
  }, [creditEvaluation, depositAmount, sendIdentifyEvent, ssnRequired]);
};
