import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { Box, Grid } from "@mui/material";
import {
  ActionType,
  AnalyticsEvent,
} from "@portal-enroll/services/segment.service";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { StateSelectField } from "@portal-shared/components/StateSelectField/StateSelectField";
import React from "react";
import { useIntl } from "react-intl";

interface SignUpPremiseFieldsProps {
  trackEvent: (event: Partial<AnalyticsEvent>) => void;
}

export const SignUpPremiseFields = ({
  trackEvent,
}: SignUpPremiseFieldsProps) => {
  const { formatMessage } = useIntl();

  const address = formatMessage({ id: "SignUpPremiseFields.address" });
  const unitNumber = formatMessage({ id: "SignUpPremiseFields.unitNumber" });
  const city = formatMessage({ id: "SignUpPremiseFields.city" });
  const zipCode = formatMessage({ id: "SignUpPremiseFields.zipCode" });

  const onFocus = (label: string) => {
    trackEvent({
      action: ActionType.focus,
      label,
    });
  };
  const handleFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => onFocus(event.target.id);
  const fieldInputProps = {
    onFocus: handleFocus,
  };

  return (
    <LoggedOutFieldsLayout>
      <Grid item>
        <RhFlexBox>
          <Box flexBasis="75%" paddingRight="15px">
            <RhTextField
              name="serviceAddress.addressLine1"
              autoComplete="address-line1"
              autoFocus
              InputProps={fieldInputProps}
              keepEmptyStrings
            >
              {address}
            </RhTextField>
          </Box>
          <Box flex={1}>
            <RhTextField
              name="serviceAddress.unitNumber"
              autoComplete="address-line2"
              InputProps={fieldInputProps}
              keepEmptyStrings
            >
              {unitNumber}
            </RhTextField>
          </Box>
        </RhFlexBox>
      </Grid>
      <Grid item>
        <RhTextField
          name="serviceAddress.city"
          autoComplete="address-level2"
          InputProps={fieldInputProps}
          keepEmptyStrings
        >
          {city}
        </RhTextField>
      </Grid>
      <Grid item>
        <RhFlexBox alignItems="flex-end">
          <Box flexBasis="75%" paddingRight="15px">
            <StateSelectField
              name="serviceAddress.state"
              translationId="SignUpPremiseFields.state"
              defaultValue="TX"
            />
          </Box>
          <Box flex={1}>
            <RhTextField
              name="serviceAddress.zipCode"
              autoComplete="postal-code"
              inputMode="numeric"
              InputProps={fieldInputProps}
              keepEmptyStrings
            >
              {zipCode}
            </RhTextField>
          </Box>
        </RhFlexBox>
      </Grid>
    </LoggedOutFieldsLayout>
  );
};
