import { blankAddress } from "@common/constants/blankAddress.constant";
import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { AddressFormType, EnrollmentType } from "@common/types/customerTypes";
import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import {
  EnrollCampaignPromoType,
  EnrollProspectType,
} from "@enroll-utils/types/prospectTypes";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal-shared/constants/offer.constant";
import { Locale } from "@portal-shared/types/LocaleTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import merge from "lodash/merge";

export interface SignUpStepValues {
  visitedSteps: SignUpStepType[];
}

export interface AcquisitionData {
  acquisitionCampaign: null | string;
  acquisitionContent: null | string;
  acquisitionMedium: null | string;
  acquisitionSource: null | string;
  acquisitionTfn: null | string;
}

// Types
export interface SignUpStateType {
  acquisition: AcquisitionData;
  activationToken?: string;
  additionalInformationRequired: boolean;
  appSource: string | null;
  areaNotCovered: boolean | null;
  autopay: boolean;
  billingPaymentMethodId: string | null;
  campaignPromo: EnrollCampaignPromoType | null;
  campaignSlug: string | null;
  confirmEmail: string;
  creditEvaluation: CreditScoreOutcome | null;
  dateOfBirth: string;
  depositAlternativeAmount: string | null;
  depositAlternativeElected: boolean;
  depositAmount: number | null;
  dunsNumber: string;
  email: string;
  emailConflictsWithExistingAccount?: boolean;
  enrollmentType: EnrollmentType | null;
  esiId: string | null;
  estimatedMonthlyUsage: number;
  featureFlagUserId: string | null;
  fingerprintId: string | null;
  firstName: string;
  googleClientId: string | null;
  id: string;
  invoiceByPrint: boolean;
  isPaperless: boolean;
  languagePreference: Locale | null;
  lastName: string;
  nosRequired: boolean;
  offerSnapshotId: IdType;
  offerSnapshotUuid: UUIDType | null;
  phone: string;
  productPrices: UUIDType[];
  prospectId: IdType | null;
  prospectUuid: UUIDType | null;
  referralFromFriendCode: string | null;
  segmentAnonId: string | null;
  sendMarketingPromos: boolean;
  serviceAddress: AddressFormType;
  serviceStartDate: string;
  ssnRequired: boolean;
  visitedSteps: SignUpStepType[];
}

export interface SignUpStoreState {
  signUp: SignUpStateType;
}

export type SetSignUpInfoAction = PayloadAction<Partial<SignUpStateType>>;
export type ManuallySetSignUpIdAction = PayloadAction<IdType | UUIDType>;

// Initial state
export const initialSignUpState = Object.freeze<SignUpStateType>({
  acquisition: {
    acquisitionCampaign: null,
    acquisitionContent: null,
    acquisitionMedium: null,
    acquisitionSource: null,
    acquisitionTfn: null,
  },
  additionalInformationRequired: false,
  appSource: null,
  areaNotCovered: null,
  autopay: false,
  billingPaymentMethodId: null,
  campaignPromo: null,
  campaignSlug: null,
  confirmEmail: "",
  creditEvaluation: null,
  dateOfBirth: "",
  depositAlternativeAmount: null,
  depositAlternativeElected: false,
  depositAmount: null,
  dunsNumber: "",
  email: "",
  emailConflictsWithExistingAccount: undefined,
  enrollmentType: null,
  esiId: "",
  estimatedMonthlyUsage: DEFAULT_AVERAGE_MONTHLY_USAGE,
  featureFlagUserId: null,
  fingerprintId: null,
  firstName: "",
  googleClientId: null,
  id: "",
  invoiceByPrint: true,
  isPaperless: false,
  languagePreference: null,
  lastName: "",
  nosRequired: false,
  offerSnapshotId: "",
  offerSnapshotUuid: null,
  phone: "",
  productPrices: [],
  prospectId: null,
  prospectUuid: null,
  referralFromFriendCode: null,
  segmentAnonId: null,
  sendMarketingPromos: true,
  serviceAddress: {
    ...blankAddress,
  },
  serviceStartDate: "",
  ssnRequired: false,
  visitedSteps: [],
});

// Handlers
export const setSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  SetSignUpInfoAction
> = (state, action) => {
  return merge({}, state, action.payload);
};

export const setSignUpInfoLanguagePreferenceHandler = (
  state: SignUpStateType,
  action: PayloadAction<Locale>
) => {
  return {
    ...state,
    languagePreference: action.payload,
  };
};

export const prospectReceivedHandler: CaseReducer<
  SignUpStateType,
  PayloadAction<Partial<EnrollProspectType>>
> = (state, { payload }) => {
  return merge({}, state, {
    areaNotCovered: payload.areaNotCovered,
    autopay: payload.autopay,
    billingPaymentMethodId: payload.billingPaymentMethodId,
    campaignSlug: payload.rcid,
    dateOfBirth: payload.dateOfBirth
      ? dayjs(payload.dateOfBirth, ISO8601_DATE_FORMAT).format(DOB_DATE_FORMAT)
      : state.dateOfBirth,
    email: payload.email,
    enrollmentType: payload.enrollmentType,
    featureFlagUserId: payload.featureFlagUserId,
    fingerprintId: payload.fingerprintId,
    firstName: payload.firstName,
    googleClientId: payload.googleClientId,
    invoiceByPrint: !payload.eBillOnly,
    isPaperless: payload.isPaperless,
    lastName: payload.lastName,
    marketingEmailOptIn: payload.marketingEmailOptIn,
    offerSnapshotUuid: payload.offerSnapshotUuid,
    offersnapshotId: payload.offersnapshotId,
    phone: payload.phone,
    productPrices: payload.productPrices,
    prospectId: payload.id,
    prospectUuid: payload.uuid,
    segmentAnonId: payload.segmentAnonId,
    serviceAddress: {
      addressLine1: payload.addressLine1,
      city: payload.city,
      state: payload.state,
      unitNumber: payload.unitNumber,
      zipCode: payload.zipCode,
    } as Partial<AddressFormType>,
    serviceStartDate: payload.serviceStartDate,
  });
};

export const clearSignUpInfoHandler: CaseReducer<
  SignUpStateType,
  PayloadAction
> = () => ({ ...initialSignUpState });

// Reducer

export const manuallySetSignUpIdHandler: CaseReducer<
  SignUpStateType,
  ManuallySetSignUpIdAction
> = (state, action) => {
  if (action.payload === state.prospectId) {
    return state;
  }

  return { ...initialSignUpState, prospectId: action.payload };
};

export const addProductPriceHandler = (
  state: SignUpStateType,
  action: PayloadAction<UUIDType>
) => {
  state.productPrices.push(action.payload);

  return state;
};

export const removeProductPriceHandler = (
  state: SignUpStateType,
  action: PayloadAction<UUIDType>
) => {
  const newPrices = state.productPrices.filter(
    (price) => price !== action.payload
  );

  return {
    ...state,
    productPrices: newPrices,
  };
};

export const clearProductPricesHandler = (state: SignUpStateType) => {
  return {
    ...state,
    productPrices: [],
  };
};

export const setSignUpAcquisitionTfn = (
  state: SignUpStateType,
  action: PayloadAction<string>
) => {
  return {
    ...state,
    acquisition: {
      ...state.acquisition,
      acquisitionTfn: action.payload,
    },
  };
};

const reducers = {
  addProductPrice: addProductPriceHandler,
  clearProductPrices: clearProductPricesHandler,
  clearSignUpInfo: clearSignUpInfoHandler,
  prospectIdChanged: setSignUpInfoHandler,
  prospectManuallySet: manuallySetSignUpIdHandler,
  prospectReceived: prospectReceivedHandler,
  removeProductPrice: removeProductPriceHandler,
  setAcquisitionTfn: setSignUpAcquisitionTfn,
  updated: setSignUpInfoHandler,
};

export const signUpSlice = createSlice({
  initialState: initialSignUpState,
  name: "signUp",
  reducers,
});

export const signUpSliceName = signUpSlice.name;
export const signUpReducer = signUpSlice.reducer;
export const setSignUpInfo = signUpSlice.actions.updated;
export const prospectFetch = signUpSlice.actions.prospectManuallySet;

export const prospectRefetch = createAction(`${signUpSlice.name}/refetch`);

export const { clearSignUpInfo, setAcquisitionTfn, prospectReceived } =
  signUpSlice.actions;

export const signUpReducers = {
  signUp: signUpReducer,
};
