import { LoggedOutFooterTranslations } from "@portal-shared/components/LoggedOutFooter/LoggedOutFooter.en.i18n";
import {
  LoggedOutFooterAllRightsReservedContainer,
  LoggedOutFooterAllRightsReservedLoggedOut,
  LoggedOutFooterUnauthenticatedContainer,
} from "@portal-shared/components/LoggedOutFooter/LoggedOutFooter.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const LoggedOutFooter = () => {
  const { translate } = useTranslations();

  const { tLoggedOutFooterTranslationsAllRightsReserved } = translate(
    LoggedOutFooterTranslations
  );

  return (
    <LoggedOutFooterUnauthenticatedContainer>
      <LoggedOutFooterAllRightsReservedContainer>
        <LoggedOutFooterAllRightsReservedLoggedOut
          color="textSecondary"
          align="center"
          variant="body2"
        >
          {tLoggedOutFooterTranslationsAllRightsReserved}
        </LoggedOutFooterAllRightsReservedLoggedOut>
      </LoggedOutFooterAllRightsReservedContainer>
    </LoggedOutFooterUnauthenticatedContainer>
  );
};
