export const loggedOutZuoraAddBankAccountFormTranslations = {
  tLoggedOutZuoraAddBankAccountFormAuthorizationFailed:
    "We're having trouble connecting to the billing service. Please try again.",
  tLoggedOutZuoraAddBankAccountFormErrorAddingBankAccount:
    "Unable to add bank account. Please verify your bank information and try again.",
  "tLoggedOutZuoraAddBankAccountFormFormError.accountNumberInvalid":
    "Please enter a valid account number.",
  "tLoggedOutZuoraAddBankAccountFormFormError.fieldEmpty":
    "Please complete all form fields.",
  "tLoggedOutZuoraAddBankAccountFormFormError.genericError":
    "We were unable to add your bank account. Please try again.",
  "tLoggedOutZuoraAddBankAccountFormFormError.routingNumberInvalid":
    "Please enter a valid routing number.",
};
