import { UUIDType } from "@common/types/apiTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacing } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { ProductPrice } from "@portal-shared/models/ProductPrice.model";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import { AddOnsOptInBackButton } from "@portal/components/ProductAddOnsOptIn/AddOnsOptInBackButton/AddOnsOptInBackButton";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ColumnRow = styled.div`
  padding: ${rhSpacing(0.5)}px;
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-basis: 35%;
  }
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-basis: 30%;
  }
  @media (min-width: ${RhythmBreakpoints.MD}px) {
    flex-basis: 25%;
  }
`;

const DetailRow = styled(RhFlexBox)`
  flex-direction: column;
  gap: 0px;
  justify-content: left;
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    gap: ${rhSpacing(6)}px;
    justify-content: center;
  }
`;

const DetailLabel = styled(RhTypography)`
  &.MuiTypography-root {
    @media (min-width: ${RhythmBreakpoints.XS}px) {
      text-align: right;
    }
  }
`;

const SingleProductTierPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacing(2)}px;
`;

const ConfirmTitleContainer = styled(RhFlexBox)`
  margin: ${rhSpacing(4)}px 0;
`;

const ContinueContainer = styled(RhFlexBox)`
  align-items: center;
  flex-direction: column;
  gap: ${rhSpacing(3)}px;
  margin-top: ${rhSpacing(4)}px;
`;

interface SingleProductTierPriceDisplayType {
  productPriceTermLength: string;
  productRate: string;
  productTierDescription: string;
  productTierName: string;
}

export interface ProductAddonOptInFormValues {
  customerAgreesToAddProductPrices: boolean;
}

const SingleProductTierPriceDisplay = (
  props: SingleProductTierPriceDisplayType
) => {
  const { t } = useRhIntl();

  const {
    productPriceTermLength,
    productRate,
    productTierDescription,
    productTierName,
  } = props;
  const additionalServiceTitle = t(
    "ProductAddOnsOptInConfirmation.additionalService"
  );
  const additionalServicePriceTitle = t("ProductAddOnsOptInConfirmation.price");
  const additionalServiceTermLengthTitle = t(
    "ProductAddOnsOptInConfirmation.termLength"
  );

  return (
    <SingleProductTierPriceContainer>
      <DetailRow>
        <ColumnRow>
          <DetailLabel variant="body2" color="textSecondary">
            {additionalServiceTitle}
          </DetailLabel>
        </ColumnRow>
        <ColumnRow>
          <RhTypography variant="body2" fontWeight={FontWeight.Semibold}>
            {productTierName}
          </RhTypography>
          <RhTypography variant="body2" color="textSecondary">
            {productTierDescription}
          </RhTypography>
        </ColumnRow>
      </DetailRow>
      <DetailRow>
        <ColumnRow>
          <DetailLabel variant="body2" color="textSecondary">
            {additionalServicePriceTitle}
          </DetailLabel>
        </ColumnRow>
        <ColumnRow>
          <RhTypography variant="body2">{productRate}</RhTypography>
        </ColumnRow>
      </DetailRow>
      <DetailRow>
        <ColumnRow>
          <DetailLabel variant="body2" color="textSecondary">
            {additionalServiceTermLengthTitle}
          </DetailLabel>
        </ColumnRow>
        <ColumnRow>
          <RhTypography variant="body2">{productPriceTermLength}</RhTypography>
        </ColumnRow>
      </DetailRow>
    </SingleProductTierPriceContainer>
  );
};

interface ProductAddOnsOptInConfirmationProps {
  onConfirmationClick(): void;
  productTiers: ProductTier[];
  selectedProductPriceIds: UUIDType[];
}

export const ProductAddOnsOptInConfirmation = ({
  productTiers,
  selectedProductPriceIds,
  onConfirmationClick,
}: ProductAddOnsOptInConfirmationProps) => {
  // All hooks
  const { t } = useRhIntl();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<ProductAddonOptInFormValues>();
  const checkBoxName = "customerAgreesToAddProductPrices";

  const title = t("ProductAddOnsOptInConfirmation.title");
  const submitCta = t("ProductAddOnsOptInConfirmation.submitCta");
  const customerAgreeCheckBoxText = t(
    "ProductAddOnsOptInConfirmation.customerAgreeCheckBoxText"
  );

  const selectedProductPricesDisplay = productTiers
    .reduce<ProductPrice[]>((acc, currentProductTier) => {
      const selectedPrices = currentProductTier.prices.filter((price) =>
        selectedProductPriceIds.includes(price.id)
      );

      return [...acc, ...selectedPrices];
    }, [])
    .map((selectedProductPrice) => {
      return (
        <SingleProductTierPriceDisplay
          key={selectedProductPrice.id}
          productPriceTermLength={selectedProductPrice.formattedTermLength}
          productRate={selectedProductPrice.formattedPricePerFrequency}
          productTierDescription={selectedProductPrice.tier.description}
          productTierName={selectedProductPrice.tier.name}
        />
      );
    });

  const onSubmit = handleSubmit((values) => {
    onConfirmationClick();
  });

  const backClickHandler = () => {
    navigate("..", { state: { retainPrices: true } });
  };

  return (
    <article aria-label={title}>
      <RhFlexBox
        flexDirection="column"
        justifyContent="space-between"
        border={0}
      >
        <ConfirmTitleContainer justifyContent="center">
          <RhTypography variant="h1">{title}</RhTypography>
        </ConfirmTitleContainer>
        <>{selectedProductPricesDisplay}</>
      </RhFlexBox>
      <form onSubmit={onSubmit}>
        <ContinueContainer>
          <Controller
            control={control}
            name={checkBoxName}
            defaultValue={false}
            rules={{ required: true }}
            render={({ field: { onChange, value }, ...field }) => {
              return (
                <RhCheckbox
                  name={checkBoxName}
                  label={customerAgreeCheckBoxText}
                  checked={value}
                  onChange={onChange}
                  {...field}
                />
              );
            }}
          />

          <RhButton
            data-tracking-click={{
              event: "Customer adding a product addon",
            }}
            type="submit"
            disabled={!isValid || isSubmitting}
            color="primary"
          >
            {submitCta}
          </RhButton>
          <AddOnsOptInBackButton onClick={backClickHandler} />
        </ContinueContainer>
      </form>
    </article>
  );
};
