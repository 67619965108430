import { premiseApi } from "@common/api/premiseApi";
import { blankAddress } from "@common/constants/blankAddress.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { AddressFormType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { isValidUSAddress } from "@portal-shared/forms/validators";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { MyAccountMailingAddressFields } from "@portal/components/MyAccountMailingAddressForm/MyAccountMailingAddressFields";
import { usePremise } from "@portal/hooks/usePremise";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { MyAccountEvents } from "@portal/services/segment.service";
import { premiseUpdated } from "@portal/slices/premiseSlice";
import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface MyAccountMailingAddressFormValues extends AddressFormType {
  mailingAddressSameAsServiceAddress: boolean;
}

const mailingAddressValidator =
  generateValidationErrorCollector<MyAccountMailingAddressFormValues>(
    isValidUSAddress
  );

export const MyAccountMailingAddressForm = () => {
  const { premise } = usePremise();
  const { t } = useRhIntl();
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();

  if (!premise) {
    return null;
  }

  const onSubmit = (
    mailingAddress: MyAccountMailingAddressFormValues,
    formApi: FormApi<MyAccountMailingAddressFormValues>
  ) => {
    return premiseApi
      .setMailingAddress(premise.id, mailingAddress)
      .then((updatedPremise) => {
        formApi.initialize(mailingAddress);
        dispatch(premiseUpdated(updatedPremise));
        flash.success(t("MyAccountMailingAddressForm.mailingAddressUpdated"));

        track({
          event: MyAccountEvents.mailingAddressUpdated,
          label: "Updated mailing address",
        });
      })
      .catch((error: RhApiError) => {
        flash.error(t("MyAccountMailingAddressForm.errorUpdatingAddress"));
      });
  };

  const initialFormValues = premise.mailingAddress
    ? {
        ...premise.mailingAddress,
        mailingAddressSameAsServiceAddress: false,
      }
    : {
        ...blankAddress,
        mailingAddressSameAsServiceAddress: true,
      };

  return (
    <Form<MyAccountMailingAddressFormValues>
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      validate={mailingAddressValidator}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <MyAccountMailingAddressFields />
        </form>
      )}
    />
  );
};
