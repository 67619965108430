import { getCookieNameWithEnv } from "@common/hooks/useAppCookies";
import { isBot } from "@common/utils/isBot";
import { prospectApi as enrollProspectApi } from "@enroll-data/api/prospectApi";
import {
  EnrollCookies,
  defaultEnrollCookiesWithEnvValues,
  prospectCookieNamePrefix,
} from "@enroll-utils/hooks/useEnrollCookies";
import { EnrollProspectType } from "@enroll-utils/types/prospectTypes";
import {
  ManuallySetSignUpIdAction,
  SetSignUpInfoAction,
  prospectFetch,
  prospectReceived,
  prospectRefetch,
  setSignUpInfo,
} from "@portal-enroll/slices/signUpSlice";
import { convertSignUpStatePayloadToProspectType } from "@portal-enroll/utils/signUpHelpers";
import { captureException, withScope } from "@sentry/react";
import isEmpty from "lodash/isEmpty";
import { call, debounce, put, takeEvery, takeLatest } from "redux-saga/effects";

const DEBOUNCE_DELAY_MS = 250;

function getProspectCookieValue() {
  const prospectUuidCookieName = getCookieNameWithEnv<EnrollCookies>(
    prospectCookieNamePrefix,
    defaultEnrollCookiesWithEnvValues
  );

  const match = document.cookie.match(
    new RegExp(`(^|;\\s*)${prospectUuidCookieName}=([^;]*)`)
  );

  return match ? decodeURIComponent(match[2]) : null;
}

export function* fetchProspectWorker({
  payload: id,
}: ManuallySetSignUpIdAction) {
  if (id) {
    const prospect: EnrollProspectType = yield call(enrollProspectApi.retrieve);

    if (prospect) {
      yield put(prospectReceived(prospect));
    }
  }
}
export function* fetchProspectWatcher() {
  yield debounce(DEBOUNCE_DELAY_MS, prospectFetch.type, fetchProspectWorker);
}

export function* refetchProspectWorker() {
  // eslint-disable-next-line no-useless-catch
  try {
    const prospect: EnrollProspectType = yield call(enrollProspectApi.retrieve);

    yield put(prospectReceived(prospect));
  } catch (err) {
    // This catch is here to remind us that we might want
    // to do something more than just notify Sentry
    throw err;
  }
}
export function* refetchProspectWatcher() {
  yield takeLatest(prospectRefetch.type, refetchProspectWorker);
}

export function* updateProspectWorker({ payload }: SetSignUpInfoAction) {
  if (isBot() || isEmpty(payload)) {
    // If this is a bot, don't create a prospect -- there's no chance
    // it'll sign up!
    return;
  }

  const prospectUuidCookieValue = getProspectCookieValue();

  const prospectData = convertSignUpStatePayloadToProspectType(payload);

  if (prospectUuidCookieValue && !isEmpty(prospectData)) {
    // eslint-disable-next-line no-useless-catch
    try {
      yield call(enrollProspectApi.update, { data: prospectData });
    } catch (err) {
      withScope(() => {
        captureException(err);
      });
    }
  }
}
export function* updateProspectWatcher() {
  yield takeEvery(setSignUpInfo.type, updateProspectWorker);
}

export const prospectSagaWatchers = [
  fetchProspectWatcher(),
  refetchProspectWatcher(),
  updateProspectWatcher(),
];
