import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  LOGGED_OUT_FORM_WIDTH,
  RhythmBreakpoints,
  SMALL_MOBILE_LOGGED_OUT_FORM_WIDTH,
} from "@design-system/theme/style.constant";
import { LOGGED_OUT_FIELD_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import { PortalToggle } from "@portal-shared/components/PortalToggle/PortalToggle";
import styled from "styled-components";

export const BillingPreferencesForm = styled.form`
  max-width: ${LOGGED_OUT_FORM_WIDTH}px;
  width: 100%;

  @media (max-width: ${RhythmBreakpoints.XXS}px) {
    max-width: ${SMALL_MOBILE_LOGGED_OUT_FORM_WIDTH}px;
  }
`;

export const BillingPreferencesFormHeader = styled.div`
  margin: 0 auto;
  margin-bottom: ${rhSpacingPx(4)};
  max-width: 60ch;
  padding: 0 2rem;
  width: 100%;
`;

export const BillingPreferencesFormSubHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
`;

export const BillingPreferencesFormRow = styled.div`
  border-top: 1px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-direction: column;
  padding-bottom: ${rhSpacingPx(4)};
  padding-top: ${rhSpacingPx(2)};
`;

export const FinePrintContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const BillingPreferencesFormPortalToggle = styled(PortalToggle)`
  display: grid;
  gap: ${rhSpacingPx(2)};
  grid-template-columns: max-content 1fr min-content;
  padding-bottom: ${rhSpacingPx(1)};

  & > svg {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    height: 22px;
    width: 20px;
  }
`;
