import { MOVE_IN, SWITCH } from "@common/types/customerTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhIconRadioField } from "@design-system/components/RhIconRadioField/RhIconRadioField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as MoveInIcon } from "@design-system/icons/MoveInIcon.svg";
import { ReactComponent as SwitchIcon } from "@design-system/icons/SwitchIcon.svg";
import { useServiceStartDatesQuery } from "@enroll-data/hooks/queries/useServiceStartDates.query";
import { SignUpServiceStartDateField } from "@portal-enroll/components/SignUpServiceStartDateField/SignUpServiceStartDateField";
import { signUpEnrollmentPageTranslations } from "@portal-enroll/pages/SignUpEnrollmentPage/SignUpEnrollmentPage.en.i18n";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import {
  ActionType,
  AnalyticsEvent,
} from "@portal-enroll/services/segment.service";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { FocusEvent, useEffect } from "react";
import { useSelector } from "react-redux";

interface SignUpEnrollmentFieldsProps {
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onChange?: (dateString: string) => void;
  trackEvent: (event: Partial<AnalyticsEvent>) => void;
}

export const SignUpEnrollmentFields = ({
  trackEvent,
  onBlur,
  onChange,
}: SignUpEnrollmentFieldsProps) => {
  const flash = useRhFlash();
  const { offerSnapshotId } = useSelector(selectSignUpState);
  const { translate } = useTranslations();
  const serviceStartDatesQuery = useServiceStartDatesQuery({
    offerSnapshotId,
  });

  const {
    tSignUpEnrollmentPageGetStartDatesError,
    tSignUpEnrollmentPageMoveIn,
    tSignUpEnrollmentPageSwitch,
  } = translate(signUpEnrollmentPageTranslations);

  useEffect(() => {
    if (serviceStartDatesQuery.isError) {
      flash.error(tSignUpEnrollmentPageGetStartDatesError);
    }
  }, [
    flash,
    serviceStartDatesQuery.isError,
    tSignUpEnrollmentPageGetStartDatesError,
  ]);

  const trackClick = (label: string) => {
    trackEvent({ action: ActionType.click, label });
  };

  const allowedDatesPicker = () => {
    if (serviceStartDatesQuery.isPending || !serviceStartDatesQuery.data) {
      return <RhCircularProgress />;
    }

    const allowedDates: string[] = serviceStartDatesQuery.data;

    const minDate = allowedDates?.[0];
    const maxDate = allowedDates?.[allowedDates.length - 1];

    return (
      <SignUpServiceStartDateField
        name="serviceStartDate"
        disabled={!allowedDates}
        minDate={minDate}
        maxDate={maxDate}
        allowedDates={allowedDates}
        InputProps={{
          onClick: () => trackClick("moveInDate"),
        }}
        onChange={onChange}
      />
    );
  };

  return (
    <LoggedOutFieldsLayout>
      <RhFlexBox
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <RhIconRadioField
          value={MOVE_IN}
          icon={<MoveInIcon />}
          label={tSignUpEnrollmentPageMoveIn}
          onClick={() => trackClick("movingIcon")}
          onBlur={onBlur}
          name="enrollmentType"
        />
        <RhIconRadioField
          value={SWITCH}
          icon={<SwitchIcon />}
          label={tSignUpEnrollmentPageSwitch}
          onClick={() => trackClick("switchingIcon")}
          onBlur={onBlur}
          name="enrollmentType"
        />
      </RhFlexBox>
      {allowedDatesPicker()}
    </LoggedOutFieldsLayout>
  );
};
