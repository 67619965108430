import { TypedEntries } from "@common/types/typeUtils";
import { AreaNotServiceableModal } from "@portal-enroll/components/AreaNotServiceableModal/AreaNotServiceableModal";
import {
  EnrollModalsAtomType,
  initialEnrollModalsAtom,
} from "@portal-enroll/components/EnrollModalsManager/EnrollModalsManager.atoms";
import { getEnrollModalsByPriority } from "@portal-enroll/components/EnrollModalsManager/getEnrollModalsByPriority";
import { useEnrollModals } from "@portal-enroll/components/EnrollModalsManager/useEnrollModals";
import { MultipleSignUpFlowTabsModal } from "@portal-enroll/components/MultipleSignUpFlowTabsModal/MultipleSignUpFlowTabsModal";
import { MultipleTdspModal } from "@portal-enroll/components/MultipleTdspModal/MultipleTdspModal";
import React, { ReactPortal } from "react";
import { createPortal } from "react-dom";

type ModalsMappedKeys = keyof typeof initialEnrollModalsAtom;
type ModalsPriorityKeys = ReturnType<typeof getEnrollModalsByPriority>[number];

type SameModalKeys = ModalsMappedKeys extends ModalsPriorityKeys
  ? ModalsPriorityKeys extends ModalsMappedKeys
    ? true
    : false
  : false;

// Dummy check to ensure modalsPriority has all modals
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: SameModalKeys = true;

export const EnrollModalsManager = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modals, removeModal } = useEnrollModals();

  const modalEntries = Object.entries(modals) as TypedEntries<typeof modals>;

  const modalsPrioritized = modalEntries
    .filter(([, value]) => value !== null)
    .sort((a, b) => {
      const modalsByPriority = getEnrollModalsByPriority();

      return modalsByPriority.indexOf(a[0]) - modalsByPriority.indexOf(b[0]);
    });

  if (modalsPrioritized.length === 0) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalKey, modalProps] = modalsPrioritized[0];

  let Modal: JSX.Element | null;

  switch (modalKey) {
    case "areaNotServiceable": {
      Modal = (
        <AreaNotServiceableModal
          handleClose={() => removeModal("areaNotServiceable")}
        />
      );
      break;
    }
    case "multipleSignUpFlowTabs": {
      Modal = <MultipleSignUpFlowTabsModal />;
      break;
    }
    case "multipleTdsp": {
      const props = modalProps as NonNullable<
        EnrollModalsAtomType["multipleTdsp"]
      >;

      Modal = (
        <MultipleTdspModal
          zipCode={props.zipCode}
          handleClose={() => {
            removeModal("multipleTdsp");
          }}
        />
      );
      break;
    }

    default: {
      // Ensure all modal cases have been covered
      modalKey satisfies never;

      Modal = null;
      break;
    }
  }

  if (Modal === null) {
    return null;
  }

  return createPortal(Modal, document.body) as ReactPortal;
};
