import { UUIDType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { TierCardVariant } from "@portal-shared/components/ProductTierCard/ProductTierCard.enums";
import {
  CurrentPlanCallout,
  PriceAndTermsContainer,
  PriceContainer,
  ProductTierCardPricePerFrequency,
  Spacer,
  StyledArticle,
  StyledButton,
  StyledDetail,
  StyledSummary,
} from "@portal-shared/components/ProductTierCard/ProductTierCard.styled";
import { ProductTierFeatures } from "@portal-shared/components/ProductTierFeatures/ProductTierFeatures";
import { TranslationKey, useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import {
  ActionType,
  GeneralEvents,
  LabelType,
  track,
} from "@portal-shared/services/segment.service";
import React from "react";
import { useLocation } from "react-router-dom";

type TierCardVariantType = keyof typeof TierCardVariant;

const variantTranslationMap: Record<TierCardVariantType, TranslationKey> = {
  default: "ProductTierCard.selectPlan",
  disabled: "ProductTierCard.selectPlan",
  enrolled: "ProductTierCard.optOut",
  selected: "ProductTierCard.deselectPlan",
};

interface ProductTierCardProps {
  onClick: (priceId: UUIDType) => void;
  productTier: ProductTier;
  variant: TierCardVariantType;
}

type TierButtonProps = {
  onClick: () => void;
  variant: TierCardVariantType;
};

const TierButton = ({ onClick, variant }: TierButtonProps) => {
  const { t } = useRhIntl();

  return (
    <StyledButton
      data-tracking-click={{
        event: "Customer clicking product addon tier button",
      }}
      variant={
        variant === TierCardVariant.enrolled ||
        variant === TierCardVariant.selected
          ? "outlined"
          : "contained"
      }
      disabled={variant === TierCardVariant.disabled}
      color="primary"
      onClick={onClick}
    >
      {t(variantTranslationMap[variant])}
    </StyledButton>
  );
};

export const ProductTierCard = ({
  productTier,
  onClick,
  variant,
}: ProductTierCardProps) => {
  const { t } = useRhIntl();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (variant === TierCardVariant.default) {
      track({
        action: ActionType.click,
        category: pathname,
        event: GeneralEvents.click,
        label: LabelType.SelectedProductTier,
        productTierId: productTier.id,
        productTierName: productTier.name,
        productTierPrice: productTier.prices[0].formattedPricePerFrequency,
        productTierPriceId: productTier.prices[0].id,
      });
    }

    onClick(productTier.prices[0].id);
  };

  const handleSeeMoreClick = () => {
    track({
      action: ActionType.click,
      event: GeneralEvents.click,
      label: LabelType.ClickedProductTierSeeMore,
      productTierId: productTier.id,
      productTierName: productTier.name,
      productTierPrice: productTier.prices[0].formattedPricePerFrequency,
      productTierPriceId: productTier.prices[0].id,
    });
  };

  return (
    <StyledArticle
      data-current-plan={variant === TierCardVariant.enrolled}
      data-selected={variant === TierCardVariant.selected}
    >
      {variant === TierCardVariant.enrolled && (
        <CurrentPlanCallout>
          <RhTypography variant="body1" color="inherit" fontWeight="Bold">
            {t("ProductTierCard.currentPlan")}
          </RhTypography>
        </CurrentPlanCallout>
      )}

      <RhTypography variant="h4" fontWeight="Bold">
        {productTier.name}
      </RhTypography>
      <RhTypography variant="body1">{productTier.description}</RhTypography>

      <StyledDetail>
        <StyledSummary
          data-open={t("ProductTierCard.seeLess")}
          data-close={t("ProductTierCard.seeMore")}
          onClick={handleSeeMoreClick}
        />
        <ProductTierFeatures productTier={productTier} />
      </StyledDetail>
      {/* spacer div */}
      <Spacer />
      <PriceAndTermsContainer>
        <PriceContainer>
          <RhTypography
            variant="h2"
            fontWeight="Bold"
            component="p"
            data-desktop
          >
            {productTier.prices[0].formattedPricePerFrequency}
          </RhTypography>
          <ProductTierCardPricePerFrequency
            variant="h2"
            fontWeight="Bold"
            component="p"
            data-mobile
          >
            {productTier.prices[0].mobileFormattedPricePerFrequency}
          </ProductTierCardPricePerFrequency>
        </PriceContainer>

        {/* Put T&C here when ready */}
        <div />
      </PriceAndTermsContainer>

      <TierButton onClick={handleClick} variant={variant} />
    </StyledArticle>
  );
};
