import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// List of UTM Mediums we want to exclude from sending Feature Flag Client events
const EXCLUDED_UTM_MEDIUMS = [
  "affiliate",
  "affiliates",
  "obtm",
  "retail",
  "state-shopping", // power to choose
];

export const useIsExcludedUtmMedium = (): boolean => {
  const [isExcludedMedium, setIsExcludedMedium] = useState<boolean>(false);
  const {
    acquisition: { acquisitionMedium },
  } = useSelector(selectSignUpState);
  // Adding utm_medium here just in case a visitor comes with utm_medium in the url,
  // acquisition just looks for rh_ params, so rh_medium.
  const [params] = useSearchParams();
  const utmMedium = params.get("utm_medium");

  useEffect(() => {
    const medium = utmMedium ?? acquisitionMedium ?? null;

    if (medium) {
      setIsExcludedMedium(EXCLUDED_UTM_MEDIUMS.includes(medium.toLowerCase()));
    }
  }, [acquisitionMedium, utmMedium]);

  return isExcludedMedium;
};
