import { useAppCookies } from "@common/hooks/useAppCookies";

type AllKeysRequiredButAllowUndefined<T> = {
  [K in keyof Required<T>]: T[K] | undefined;
};
export interface PortalSharedCookiesWithEnv {}

const defaultPortalSharedCookiesWithEnvValues: AllKeysRequiredButAllowUndefined<PortalSharedCookiesWithEnv> =
  {};

export interface PortalSharedCookies extends PortalSharedCookiesWithEnv {
  django_language?: "en" | "es";
}

const defaultPortalSharedCookiesValues: AllKeysRequiredButAllowUndefined<PortalSharedCookies> =
  {
    django_language: "en",
    ...defaultPortalSharedCookiesWithEnvValues,
  };

// Only used for any cookie use in th logged in experience
export const usePortalSharedCookies = () => {
  return useAppCookies<PortalSharedCookies>(
    defaultPortalSharedCookiesValues,
    defaultPortalSharedCookiesWithEnvValues
  );
};
