import { formatKwh } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  SliderOnChangeEvent,
  SliderOnChangeFunction,
  SliderOnChangeValueType,
} from "@design-system/types/domEventTypes";
import { monthlyUsageSliderTranslations } from "@portal-enroll/components/MonthlyUsageSlider/MonthlyUsageSlider.en.i18n";
import {
  DescriptionContainer,
  DescriptionImage,
  MonthlyUsageSliderContainer,
  StyledUsageSliderUnorderedList,
} from "@portal-enroll/components/MonthlyUsageSlider/MonthlyUsageSlider.styled";
import { MonthlyUsageSliderInput } from "@portal-enroll/components/MonthlyUsageSlider/MonthlyUsageSliderInput";
import { ReactComponent as House1000 } from "@portal-enroll/images/House1000.svg";
import { ReactComponent as House1500 } from "@portal-enroll/images/House1500.svg";
import { ReactComponent as House2000 } from "@portal-enroll/images/House2000.svg";
import { ReactComponent as House2500 } from "@portal-enroll/images/House2500.svg";
import { ReactComponent as House3000 } from "@portal-enroll/images/House3000.svg";
import { ReactComponent as House3500 } from "@portal-enroll/images/House3500.svg";
import { ReactComponent as House500 } from "@portal-enroll/images/House500.svg";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal-shared/constants/offer.constant";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useState } from "react";

type DescriptionRecord = {
  Icon: JSX.Element;
  descriptor: string;
  preference: string;
};

interface MonthlyUsageSliderProps {
  onChange: SliderOnChangeFunction;
}

export const MonthlyUsageSlider = ({ onChange }: MonthlyUsageSliderProps) => {
  const { translate } = useTranslations();

  const {
    tMonthlyUsageSliderHomeDescriptor1000,
    tMonthlyUsageSliderHomeDescriptor1500,
    tMonthlyUsageSliderHomeDescriptor2000,
    tMonthlyUsageSliderHomeDescriptor2500,
    tMonthlyUsageSliderHomeDescriptor3000,
    tMonthlyUsageSliderHomeDescriptor3500,
    tMonthlyUsageSliderHomeDescriptor500,
    tMonthlyUsageSliderHomePrefDescriptor1000,
    tMonthlyUsageSliderHomePrefDescriptor1500,
    tMonthlyUsageSliderHomePrefDescriptor2000,
    tMonthlyUsageSliderHomePrefDescriptor2500,
    tMonthlyUsageSliderHomePrefDescriptor3000,
    tMonthlyUsageSliderHomePrefDescriptor3500,
    tMonthlyUsageSliderHomePrefDescriptor500,
  } = translate(monthlyUsageSliderTranslations);

  const monthlyUsageSliderConfig: Record<string, DescriptionRecord> = {
    1000: {
      Icon: <House1000 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor1000,
      preference: tMonthlyUsageSliderHomePrefDescriptor1000,
    },
    1500: {
      Icon: <House1500 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor1500,
      preference: tMonthlyUsageSliderHomePrefDescriptor1500,
    },
    2000: {
      Icon: <House2000 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor2000,
      preference: tMonthlyUsageSliderHomePrefDescriptor2000,
    },
    2500: {
      Icon: <House2500 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor2500,
      preference: tMonthlyUsageSliderHomePrefDescriptor2500,
    },
    3000: {
      Icon: <House3000 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor3000,
      preference: tMonthlyUsageSliderHomePrefDescriptor3000,
    },
    3500: {
      Icon: <House3500 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor3500,
      preference: tMonthlyUsageSliderHomePrefDescriptor3500,
    },
    500: {
      Icon: <House500 title="House" />,
      descriptor: tMonthlyUsageSliderHomeDescriptor500,
      preference: tMonthlyUsageSliderHomePrefDescriptor500,
    },
  };

  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] = useState<number>(
    DEFAULT_AVERAGE_MONTHLY_USAGE
  );

  const handleChange: SliderOnChangeFunction = (
    event: SliderOnChangeEvent,
    value: SliderOnChangeValueType
  ) => {
    if (!Array.isArray(value)) {
      setEstimatedMonthlyUsage(value);
      onChange(event, value);
    }
  };

  const { Icon, descriptor, preference } =
    monthlyUsageSliderConfig[estimatedMonthlyUsage.toString()];

  return (
    <MonthlyUsageSliderContainer>
      <DescriptionContainer>
        <DescriptionImage>{Icon}</DescriptionImage>
        <div>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {formatKwh(estimatedMonthlyUsage, 0)}
          </RhTypography>
          <StyledUsageSliderUnorderedList>
            <li>
              <RhTypography variant="body1">{descriptor}</RhTypography>
            </li>
            <li>
              <RhTypography variant="body1">{preference}</RhTypography>
            </li>
          </StyledUsageSliderUnorderedList>
        </div>
      </DescriptionContainer>
      <MonthlyUsageSliderInput
        value={estimatedMonthlyUsage}
        onChange={handleChange}
      />
    </MonthlyUsageSliderContainer>
  );
};
