import {
  StyledCell,
  StyledLabelContainer,
} from "@portal-enroll/components/SignUpSummaryInfo/SignUpSummaryInfo.styled";
import React, { ReactNode } from "react";

interface SummaryLabelCellProps {
  children: ReactNode;
}

export const SummaryLabelCell = (props: SummaryLabelCellProps) => {
  const { children } = props;

  return (
    <StyledLabelContainer>
      <StyledCell variant="body1" color="textSecondary">
        {children}
      </StyledCell>
    </StyledLabelContainer>
  );
};
