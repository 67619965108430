import { billingDetailsWatcher } from "@portal/sagas/billingDetails.saga";
import { premiseWatcher } from "@portal/sagas/premise.saga";
import { rewardsWatcher } from "@portal/sagas/rewards.saga";
import { all } from "redux-saga/effects";

// Register your sagas here
const sagas = [billingDetailsWatcher(), rewardsWatcher(), premiseWatcher()];

export function* rootSaga() {
  yield all(sagas);
}
