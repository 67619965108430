import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import { StyledSignUpLink } from "@design-system/components/RhHeader/RhHeader.styled";
import { enrollOffersPath } from "@enroll-utils/constants/routePaths";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { loggedOutHeaderTranslations } from "@portal/components/LoggedOutHeader/LoggedOutHeader.en.i18n";
import { PortalHeaderLogo } from "@portal/components/PortalHeaderLogo/PortalHeaderLogo";
import React from "react";

export const LoggedOutHeader = () => {
  const { translate } = useTranslations();

  const { tLoggedOutHeaderSignUp } = translate(loggedOutHeaderTranslations);

  const signUpLink = (
    <StyledSignUpLink key={enrollOffersPath()} to={enrollOffersPath()}>
      {tLoggedOutHeaderSignUp}
    </StyledSignUpLink>
  );

  const links = [signUpLink];

  return <RhHeader items={links} logo={<PortalHeaderLogo />} />;
};
