import { formatDateOfBirth } from "@common/forms/formatters";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import {
  ActionType,
  AnalyticsEvent,
} from "@portal-enroll/services/segment.service";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import React from "react";
import { useIntl } from "react-intl";

interface SignUpNameFieldsProps {
  disableAutofocus: boolean;
  trackEvent: (event: Partial<AnalyticsEvent>) => void;
}

export const SignUpNameFields = ({
  trackEvent,
  disableAutofocus,
}: SignUpNameFieldsProps) => {
  const { formatMessage } = useIntl();
  const firstName = formatMessage({ id: "signUpNameFields.firstName" });
  const lastName = formatMessage({ id: "signUpNameFields.lastName" });
  const dateOfBirth = formatMessage({ id: "signUpNameFields.dateOfBirth" });

  const onFocus = (label: string) => {
    trackEvent({
      action: ActionType.focus,
      label,
    });
  };
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) =>
    onFocus(event.target.id);

  const fieldInputProps = {
    onFocus: handleFocus,
  };

  return (
    <LoggedOutFieldsLayout>
      <RhTextField
        name="firstName"
        autoComplete="given-name"
        autoFocus={!disableAutofocus}
        InputProps={fieldInputProps}
        keepEmptyStrings
      >
        {firstName}
      </RhTextField>
      <RhTextField
        name="lastName"
        autoComplete="family-name"
        InputProps={fieldInputProps}
        keepEmptyStrings
      >
        {lastName}
      </RhTextField>
      <RhTextField
        autoComplete="bday"
        format={formatDateOfBirth}
        parse={formatDateOfBirth}
        name="dateOfBirth"
        placeholder="MM/DD/YYYY"
        inputMode="numeric"
        InputProps={fieldInputProps}
        keepEmptyStrings
      >
        {dateOfBirth}
      </RhTextField>
    </LoggedOutFieldsLayout>
  );
};
