import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { RhCalendarDatePicker } from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker";
import { Box } from "@mui/material";
import { SignUpServiceStartDateFieldTranslations } from "@portal-enroll/components/SignUpServiceStartDateField/SignUpServiceStartDateField.en.i18n";
import { StyledSignUpServiceStartDateFieldHint } from "@portal-enroll/components/SignUpServiceStartDateField/SignUpServiceStartDateField.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import dayjs, { Dayjs } from "dayjs";
import React, { MouseEventHandler } from "react";
import * as RFF from "react-final-form";

interface RhDateFieldProps {
  InputProps?: {
    onClick: MouseEventHandler<HTMLDivElement>;
  };
  allowedDates: string[];
  disabled?: boolean;
  maxDate?: string;
  minDate?: string;
  name: string;
  onChange?: (dateString: string) => void;
}

export const SignUpServiceStartDateField = ({
  name,
  allowedDates,
  onChange,
  ...restProps
}: RhDateFieldProps) => {
  const { translate } = useTranslations();

  const {
    tSignUpServiceStartDateFieldCancel,
    tSignUpServiceStartDateFieldNoSetup,
    tSignUpServiceStartDateFieldOk,
    tSignUpServiceStartDateFieldWhenToStartService,
  } = translate(SignUpServiceStartDateFieldTranslations);

  const { maxDate, minDate } = restProps;

  const firstDate = allowedDates[0];

  const allowedDayJsDates = allowedDates.map(dayjs);

  const {
    input: { value, type: unusedType, onChange: rffOnChange, ...restInput },
  } = RFF.useField<string>(name, {
    initialValue: firstDate,
    type: "date",
  });

  const handleChange = (dateString: string) => {
    rffOnChange(dateString);

    if (onChange) {
      onChange(dateString);
    }
  };

  restInput.onChange = handleChange;

  return (
    <Box position="relative" width="100%">
      <RhCalendarDatePicker
        label={tSignUpServiceStartDateFieldWhenToStartService}
        format="ddd MM/DD/YYYY"
        inputName="startDate"
        value={dayjs(value)}
        maxDate={dayjs(maxDate)}
        minDate={dayjs(minDate)}
        onChange={(date: Dayjs) => {
          const formattedDate = date.format(ISO8601_DATE_FORMAT);

          handleChange(formattedDate);
        }}
        okLabel={tSignUpServiceStartDateFieldOk}
        cancelLabel={tSignUpServiceStartDateFieldCancel}
        allowedDates={allowedDayJsDates}
      />

      <StyledSignUpServiceStartDateFieldHint variant="body2">
        {tSignUpServiceStartDateFieldNoSetup}
      </StyledSignUpServiceStartDateFieldHint>
    </Box>
  );
};
