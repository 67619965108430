import { rhSpacingPx } from "@design-system/theme/spacing";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import styled from "styled-components";

interface Props {
  $noMargin: boolean;
}

export const StyledSignUpFieldsLayoutContainer = styled.div<Props>`
  margin-bottom: ${({ $noMargin }) =>
    $noMargin ? "0" : rhSpacingPx(LOGGED_OUT_CONTAINER_SPACING)};
  margin-top: ${({ $noMargin }) =>
    $noMargin ? "0" : rhSpacingPx(LOGGED_OUT_CONTAINER_SPACING)};
`;
