import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import {
  AREA_NOT_SERVICED,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
} from "@common/types/errorTypes";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { useEnrollModals } from "@portal-enroll/components/EnrollModalsManager/useEnrollModals";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { RhythmRTBs } from "@portal-enroll/components/RhythmRTBs/RhythmRTBs";
import { UpdateZipCode } from "@portal-enroll/components/UpdateZipCode/UpdateZipCode";
import { useAcquisitionCampaignFromQueryParam } from "@portal-enroll/hooks/useAcquisitionCampaignFromQueryParam";
import {
  PageContainer,
  ReasonsToBelieveContainer,
  ZipCodeAndTitleHeader,
} from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPage.styled";
import { EnrollOffersPageAvailability } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageAvailability/EnrollOffersPageAvailability";
import { EnrollOffersPageBackground } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageBackground/EnrollOffersPageBackground";
import { EnrollOffersPageBillCostEstimator } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageBillCostEstimator/EnrollOffersPageBillCostEstimator";
import { EnrollOffersPageHeader } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageHeader/EnrollOffersPageHeader";
import { EnrollOffersPageOfferSnapshotCards } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageOfferSnapshotCards/EnrollOffersPageOfferSnapshotCards";
import { EnrollOffersPageRepPriceComparisonChart } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageRepPriceComparisonChart/EnrollOffersPageRepPriceComparisonChart";
import { useEnrollQueryParams } from "@portal-enroll/pages/EnrollOffersPage/useEnrollQueryParams";
import { useOfferSnapshotsQuery } from "@portal-enroll/queries/useOfferSnapshotsQuery";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal-shared/constants/offer.constant";
import { OfferSnapshotOrderOptions } from "@portal-shared/types/offerSnapshotTypes";
import React, { useEffect, useId, useState } from "react";

const campaignSlugsToShowFilters: RhythmCampaignSlugs[] = [
  RhythmCampaignSlugs.Default,
];

export const EnrollOffersPage = () => {
  const prospect = useProspectFromContext();
  const { addModal } = useEnrollModals();

  const { mutate } = useProspectUpdateMutation();

  useEnrollQueryParams();
  useAcquisitionCampaignFromQueryParam();

  const { zipCode, dunsNumber } = prospect;
  const campaignSlug = prospect.rcid ?? RhythmCampaignSlugs.Default;
  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] = useState<number>(
    DEFAULT_AVERAGE_MONTHLY_USAGE
  );

  const offerSnapshotsQuery = useOfferSnapshotsQuery({
    queryOptions: {
      enabled: Boolean(dunsNumber) || Boolean(zipCode),
      retry: false,
    },
    searchOptions: {
      campaignSlug,
      dunsNumber: dunsNumber ?? undefined,
      ordering: OfferSnapshotOrderOptions.PRIORITY_ASCENDING,
      zipCode: dunsNumber ? "" : (zipCode ?? undefined),
    },
  });

  useEffect(() => {
    if (!offerSnapshotsQuery.error) {
      return;
    }

    const { error } = offerSnapshotsQuery;

    if (error.data.errorCode === PRICING_OFFERS_MULTIPLE_UTILITIES) {
      addModal({
        multipleTdsp: {
          prospectUUID: prospect.uuid,
          zipCode: zipCode ?? "",
        },
      });
    }

    if (error.data.errorCode === AREA_NOT_SERVICED) {
      addModal({
        areaNotServiceable: true,
      });
    }
  }, [offerSnapshotsQuery.error, addModal, zipCode, prospect.uuid]);

  const handleZipCodeUpdate = (newZipCode: string) => {
    mutate({
      data: {
        dunsNumber: "",
        zipCode: newZipCode,
      },
    });
  };

  const noOfferSnapshots =
    !offerSnapshotsQuery.isFetching && !offerSnapshotsQuery.data?.count;

  const showCompetitorPlanComparison =
    campaignSlugsToShowFilters.includes(campaignSlug as RhythmCampaignSlugs) &&
    !noOfferSnapshots;

  const offerSnapshots = offerSnapshotsQuery.data?.results || [];

  const showBillCostEstimator = !offerSnapshots.some(
    (offerSnapshot) => offerSnapshot.solarEligible
  );

  const billCostEstimatorId = useId();
  const competitorComparisonId = useId();

  if (!zipCode) {
    return (
      <EnrollOffersPageAvailability>
        <UpdateZipCode
          onZipCodeSubmit={handleZipCodeUpdate}
          zipCode={zipCode ?? ""}
        />
      </EnrollOffersPageAvailability>
    );
  }

  return (
    <PageContainer $noPadding>
      <ZipCodeAndTitleHeader>
        <UpdateZipCode
          onZipCodeSubmit={handleZipCodeUpdate}
          zipCode={zipCode}
        />
        <EnrollOffersPageHeader />
      </ZipCodeAndTitleHeader>

      <EnrollOffersPageOfferSnapshotCards
        offerSnapshots={offerSnapshots}
        isPending={offerSnapshotsQuery.isPending}
        estimatedMonthlyUsage={estimatedMonthlyUsage}
        billCalculatorId={billCostEstimatorId}
        competitorComparisonId={competitorComparisonId}
        showBillCostEstimator={showBillCostEstimator}
        showCompetitorComparison={showCompetitorPlanComparison}
      />

      <ReasonsToBelieveContainer>
        <RhythmRTBs />
      </ReasonsToBelieveContainer>

      {showCompetitorPlanComparison ? (
        <EnrollOffersPageRepPriceComparisonChart
          estimatedMonthlyUsage={estimatedMonthlyUsage}
          offerSnapshots={offerSnapshots}
          id={competitorComparisonId}
        />
      ) : null}
      {showBillCostEstimator ? (
        <EnrollOffersPageBillCostEstimator
          setEstimatedMonthlyUsage={setEstimatedMonthlyUsage}
          estimatedMonthlyUsage={estimatedMonthlyUsage}
          offerSnapshots={offerSnapshots || []}
          id={billCostEstimatorId}
        />
      ) : null}
      <EnrollOffersPageBackground />
    </PageContainer>
  );
};
