import { BrandLogo } from "@common/components/BrandLogo";
import { enrollHeaderLogoTranslations } from "@portal-enroll/components/EnrollHeaderLogo/EnrollHeaderLogo.en.i18n";
import { EnrollHeaderLogoLink } from "@portal-enroll/components/EnrollHeaderLogo/EnrollHeaderLogo.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";

interface EnrollHeaderLogoProps {}

export const EnrollHeaderLogo = (props: EnrollHeaderLogoProps) => {
  const { translate } = useTranslations();
  const { tEnrollHeaderHome } = translate(enrollHeaderLogoTranslations);

  const marketingUrl = brandInformation.urlsHomePage;

  return (
    <EnrollHeaderLogoLink
      to={marketingUrl}
      external
      target="_self"
      aria-label={tEnrollHeaderHome}
    >
      <BrandLogo />
    </EnrollHeaderLogoLink>
  );
};
