import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { areaNotServiceableModalTranslations } from "@portal-enroll/components/AreaNotServiceableModal/AreaNotServiceableModal.en.i18n";
import {
  AreaNotServiceableButton,
  AreaNotServiceableInner,
  AreaNotServiceableModalSubtitle,
  AreaNotServiceableModalTitle,
  AreaNotServiceableSubtitleContainer,
  OrSeparator,
  StyledAreaNotServiceableModal,
} from "@portal-enroll/components/AreaNotServiceableModal/AreaNotServiceableModal.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

interface UtilityInput {
  email: string;
}

interface AreaNotServiceableModalFormProps {
  onEmailSubmit: SubmitHandler<UtilityInput>;
  onTryAnotherZipCode: () => void;
}

export const AreaNotServiceableModalForm = ({
  onEmailSubmit,
  onTryAnotherZipCode,
}: AreaNotServiceableModalFormProps) => {
  const { translate } = useTranslations();
  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting, errors },
  } = useForm<UtilityInput>({});
  const {
    tAreaNotServiceableModalSubheader,
    tAreaNotServiceableModalSubmit,
    tAreaNotServiceableModalTitle,
    tAreaNotServiceableModalInvalidEmail,
    tAreaNotServiceableModalEmail,
    tAreaNotServiceableModalTryAnotherZipCode,
    tAreaNotServiceableModalOr,
  } = translate(areaNotServiceableModalTranslations);

  const emailProps = register("email", {
    pattern: {
      message: tAreaNotServiceableModalInvalidEmail,
      value: EMAIL_VALIDATION_REGEX,
    },
    required: true,
  });

  return (
    <StyledAreaNotServiceableModal>
      <AreaNotServiceableModalTitle variant="h1" align="center">
        {tAreaNotServiceableModalTitle}
      </AreaNotServiceableModalTitle>
      <AreaNotServiceableSubtitleContainer>
        <AreaNotServiceableModalSubtitle variant="subtitle2" align="center">
          {tAreaNotServiceableModalSubheader}
        </AreaNotServiceableModalSubtitle>
      </AreaNotServiceableSubtitleContainer>
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        <AreaNotServiceableInner>
          <RhTextInput
            errorMessage={errors.email?.message}
            error={!!errors.email}
            placeholder=""
            color="primary"
            autoFocus
            {...emailProps}
          >
            {tAreaNotServiceableModalEmail}
          </RhTextInput>
          <AreaNotServiceableButton
            data-tracking-click={{
              event:
                "Prospect signing up for updates about when will we service the area",
            }}
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting}
            type="submit"
          >
            {tAreaNotServiceableModalSubmit}
          </AreaNotServiceableButton>
          <OrSeparator>- {tAreaNotServiceableModalOr} -</OrSeparator>
          <RhButton
            data-tracking-click={{
              event: "prospect updating zip code",
            }}
            color="primary"
            variant="outlined"
            onClick={onTryAnotherZipCode}
          >
            {tAreaNotServiceableModalTryAnotherZipCode}
          </RhButton>
        </AreaNotServiceableInner>
      </form>
    </StyledAreaNotServiceableModal>
  );
};
