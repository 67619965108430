import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PageContainer } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPage.styled";
import { enrollOffersPageAvailabilityTranslations } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageAvailability/EnrollOffersPageAvailability.en.i18n";
import { AvailabilityPageContainer } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageAvailability/EnrollOffersPageAvailability.styled";
import { EnrollOffersPageBackground } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageBackground/EnrollOffersPageBackground";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface EnrollOffersPageAvailabilityProps {
  children: React.ReactNode;
}

export const EnrollOffersPageAvailability = ({
  children,
}: EnrollOffersPageAvailabilityProps) => {
  const { translate } = useTranslations();

  const { tEnrollOffersPageAvailabilityTitle } = translate(
    enrollOffersPageAvailabilityTranslations
  );

  return (
    <PageContainer>
      <AvailabilityPageContainer>
        <RhTypography variant="h1">
          {tEnrollOffersPageAvailabilityTitle}
        </RhTypography>
        {children}
      </AvailabilityPageContainer>
      <EnrollOffersPageBackground />
    </PageContainer>
  );
};
