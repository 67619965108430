import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { isCustomer } from "@common/utils/authenticationHelpers";
import { Show500Page } from "@portal-shared/utils/errors";
import { GlobalAnnouncement } from "@portal/components/GlobalAnnouncement/GlobalAnnouncement";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremise } from "@portal/hooks/usePremise";
import {
  accountCreationPendingPath,
  signOutPath,
} from "@portal/routes/routePaths";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const FetchPortalDependencies = () => {
  const { data: groups } = useAuthenticatedUserGroups();
  const {
    error: premiseError,
    premise,
    requestMonitor: premiseRequestMonitor,
  } = usePremise();
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    enabled: premise !== null,
    premiseId: premise?.id || "",
  });

  if (groups === null) {
    return null;
  }

  const requestsPending = premiseRequestMonitor.isPending;

  if (!isCustomer(groups)) {
    return null;
  }

  if (premiseError || accountSummaryQuery.isError) {
    if (premiseError?.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
      return <Navigate to={signOutPath()} />;
    }

    if (premiseError) {
      throw new Show500Page(
        `Could not get premise: ${premiseError.data.errorCode}`
      );
    }
  }
  if (!requestsPending && premise && !premise.billingAccountId) {
    return <Navigate to={accountCreationPendingPath()} />;
  }

  return (
    <>
      <GlobalAnnouncement />
      <Outlet />
    </>
  );
};
