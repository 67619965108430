import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { IRONHIDE_SIGN_IN_URL } from "@portal-enroll/constants/urls.constant";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

export const IronhideCustomerMessage = () => {
  const { tJsx } = useRhIntl();
  const message = tJsx("IronhideCustomerMessage.alreadyACustomer", {
    link: (url) => (
      <a href={IRONHIDE_SIGN_IN_URL} rel="noreferrer">
        <RhTypography
          component="span"
          variant="body2"
          fontWeight={FontWeight.Semibold}
        >
          {url}
        </RhTypography>
      </a>
    ),
  });

  return <span>{message}</span>;
};
