import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CartProductTier } from "@portal-enroll/components/CartSelectedProductTiers/CartProductTier";
import { StyledCartSelectedProductTiers } from "@portal-enroll/components/CartSelectedProductTiers/CartSelectedProductTiers.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import React from "react";

interface CartSelectedProductTiersProps {
  closeCart: () => void;
  selectedProductTiers: ProductTier[];
}

export const CartSelectedProductTiers = ({
  closeCart,
  selectedProductTiers,
}: CartSelectedProductTiersProps) => {
  const { t } = useRhIntl();

  if (!selectedProductTiers.length) {
    return null;
  }

  return (
    <StyledCartSelectedProductTiers>
      <RhTypography variant="h3" fontWeight="Bold" component="p">
        {t("CartSelectedProductTiers.additionalServices")}
      </RhTypography>

      {selectedProductTiers.map((tier) => (
        <CartProductTier
          key={tier.id}
          productTier={tier}
          closeCart={closeCart}
        />
      ))}
    </StyledCartSelectedProductTiers>
  );
};
