import { formatCurrency } from "@common/utils/dataFormatters";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@mui/material";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpDepositRequiredPageTranslations } from "@portal-enroll/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage.en.i18n";
import { ActionType } from "@portal-enroll/services/segment.service";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutZuoraCardForm } from "@portal-shared/components/LoggedOutZuoraCardForm/LoggedOutZuoraCardForm";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";

interface DepositFormProps {
  depositAmount: number | null;
}

export const DepositForm = (props: DepositFormProps) => {
  const { depositAmount } = props;
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const flash = useRhFlash();

  const { translate, translateJsx } = useTranslations();

  const {
    tSignUpDepositRequiredPageCouldBe,
    tSignUpDepositRequiredPageDepositReturned,
  } = translate(signUpDepositRequiredPageTranslations);

  const {
    tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit,
    tSignUpDepositRequiredPageSorryWeNeedDeposit,
  } = translateJsx({
    tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit: {
      link: (faqPage: string) => (
        <RhAnchor
          href={brandInformation.urlsFaqsSatisfyDeposit}
          target="_blank"
          onClick={() =>
            trackEvent({ action: ActionType.click, label: "depositFaqSite" })
          }
        >
          {faqPage}
        </RhAnchor>
      ),
    },
    tSignUpDepositRequiredPageSorryWeNeedDeposit: {
      deposit: formatCurrency(depositAmount ?? 0),
    },
  });

  return (
    <>
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING}>
        <LoggedOutPageHeader
          headerText={tSignUpDepositRequiredPageSorryWeNeedDeposit}
        />
      </Box>
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING} textAlign="center">
        <RhTypography variant="subtitle2" color="textSecondary">
          {tSignUpDepositRequiredPageCouldBe}
        </RhTypography>
      </Box>
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING} textAlign="center">
        <RhTypography variant="subtitle2" color="textSecondary">
          {tSignUpDepositRequiredPageDepositReturned}
        </RhTypography>
      </Box>
      <Box paddingBottom={LOGGED_OUT_CONTAINER_SPACING} textAlign="center">
        <RhTypography variant="subtitle2" color="textSecondary">
          {tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit}
        </RhTypography>
      </Box>
      <LoggedOutZuoraCardForm
        onSuccess={({ refId }) => {
          signUpClickNextStepHandler({
            nextStep: "summary",
            signUpData: { billingPaymentMethodId: refId },
            track: true,
          });
        }}
        onFailure={flash.error}
      />
    </>
  );
};
