import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { isCSRs, isOps } from "@common/utils/authenticationHelpers";
import { rhWindow } from "@common/utils/rhWindow";
import { useOktaAuth } from "@okta/okta-react";
import { RHAPSODY_BASE_URL } from "@portal-shared/settings/config";
import { selectAnalytics } from "@portal/selectors/analyticsSelectors";
import { selectCurrentCustomerId } from "@portal/selectors/authenticationSelectors";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import { analyticsDisableSendCustomerLoginEvent } from "@portal/slices/analyticsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useRedirectIfCsrOrOps = () => {
  const { data: groups } = useAuthenticatedUserGroups();

  if (isCSRs(groups) || isOps(groups)) {
    rhWindow.location.replace(RHAPSODY_BASE_URL);
  }
};

export const useSendCustomerLoginEvent = () => {
  const { sendCustomerLoginEvent } = useSelector(selectAnalytics);
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;
  const dispatch = useDispatch();
  const customerId = useSelector(selectCurrentCustomerId);

  useEffect(() => {
    if (isAuthenticated && sendCustomerLoginEvent && customerId) {
      track({
        action: ActionType.customerLogin,
        category: "portal",
        event: EventType.portalCustomerLogin,
        label: "Customer logged in",
        value: customerId,
      });
      dispatch(analyticsDisableSendCustomerLoginEvent());
    }
  }, [customerId, dispatch, isAuthenticated, sendCustomerLoginEvent]);
};
