import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { RhCheckboxField } from "@design-system/components/RhCheckboxField/RhCheckboxField";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import {
  ActionType,
  AnalyticsEvent,
} from "@portal-enroll/services/segment.service";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

interface SignUpContactFieldsProps {
  trackEvent: (event: Partial<AnalyticsEvent>) => void;
}

export const SignUpContactFields = ({
  trackEvent,
}: SignUpContactFieldsProps) => {
  const { t } = useRhIntl();
  const email = t("signUpContactFields.email");
  const confirmEmail = t("signUpContactFields.confirmEmail");
  const phone = t("signUpContactFields.phone");
  const sendMarketingPromos = t("signUpContactFields.sendMarketingPromos");

  const onFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    trackEvent({
      action: ActionType.focus,
      label: event.target.id,
    });
  };

  const handleMarketingPromoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    trackEvent({
      action: ActionType.click,
      label: "disableEmailUpdates",
      value: checked,
    });
  };

  const fieldInputProps = {
    onFocus,
  };

  return (
    <LoggedOutFieldsLayout>
      <RhTextField
        name="email"
        type="email"
        autoComplete="email"
        autoFocus
        InputProps={fieldInputProps}
      >
        {email}
      </RhTextField>
      <RhTextField
        name="confirmEmail"
        type="email"
        autoComplete="email"
        inputMode="email"
        InputProps={fieldInputProps}
      >
        {confirmEmail}
      </RhTextField>
      <RhTextField
        autoComplete="phone"
        format={maskPhone}
        parse={unmaskPhone}
        name="phone"
        type="tel"
        inputMode="numeric"
        placeholder="555-555-5555"
        InputProps={fieldInputProps}
      >
        {phone}
      </RhTextField>
      <RhCheckboxField
        onChange={handleMarketingPromoChange}
        label={sendMarketingPromos}
        name="sendMarketingPromos"
      />
    </LoggedOutFieldsLayout>
  );
};
