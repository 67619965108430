import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { ReactComponent as CloudPlug } from "@portal-enroll/images/CloudPlug.svg";
import { signUpProvideAdditionalInformationPageTranslations } from "@portal-enroll/pages/SignUpProvideAdditionalInformationPage/SignUpProvideAdditionalInformationPage.en.i18n";
import {
  ProvideAdditionalInformationPageContainer,
  StyledBold,
} from "@portal-enroll/pages/SignUpProvideAdditionalInformationPage/SignUpProvideAdditionalInformationPage.styled";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";
import { useSelector } from "react-redux";

export const SignUpProvideAdditionalInformationPage = () => {
  const { translate, translateJsx } = useTranslations();
  const {
    tSignUpProvideAdditionalInformationPageGetSetUp,
    tSignUpProvideAdditionalInformationPageWelcomeBack,
    tSignUpProvideAdditionalInformationPageSinceYouWere,
    tSignUpProvideAdditionalInformationPageThankYou,
  } = translate(signUpProvideAdditionalInformationPageTranslations);

  const { additionalInformationRequired } = useSelector(selectSignUpState);

  const {
    tSignUpProvideAdditionalInformationPagePleaseContactCustomerCare,
    tSignUpProvideAdditionalInformationPagePleaseContactUs,
  } = translateJsx({
    tSignUpProvideAdditionalInformationPagePleaseContactCustomerCare: {
      phone: brandInformation.phoneNumbersOpsDisplay,
      phoneTag: (content: string) => (
        <StyledBold>
          <RhAnchor
            href={`tel:${brandInformation.phoneNumbersOpsNumber}`}
            color="textSecondary"
          >
            {content}
          </RhAnchor>
        </StyledBold>
      ),
    },
    tSignUpProvideAdditionalInformationPagePleaseContactUs: {
      phone: formatPhoneNumber(brandInformation.phoneNumbersOpsBadDebt),
      phoneTag: (content: string) => (
        <StyledBold>
          <RhAnchor
            href={`tel:${brandInformation.phoneNumbersOpsBadDebt}`}
            color="textSecondary"
          >
            {content}
          </RhAnchor>
        </StyledBold>
      ),
    },
  });

  return (
    <SignUpPageLayout hideBackButton>
      <ProvideAdditionalInformationPageContainer>
        <CloudPlug />
        <LoggedOutPageHeader
          headerText={
            additionalInformationRequired
              ? tSignUpProvideAdditionalInformationPageWelcomeBack
              : tSignUpProvideAdditionalInformationPageGetSetUp
          }
        />
        <RhTypography variant="subtitle1" color="textSecondary">
          {additionalInformationRequired
            ? tSignUpProvideAdditionalInformationPageSinceYouWere
            : tSignUpProvideAdditionalInformationPageThankYou}
        </RhTypography>
        <RhTypography variant="subtitle1" color="textSecondary">
          {additionalInformationRequired
            ? tSignUpProvideAdditionalInformationPagePleaseContactUs
            : tSignUpProvideAdditionalInformationPagePleaseContactCustomerCare}
        </RhTypography>
      </ProvideAdditionalInformationPageContainer>
    </SignUpPageLayout>
  );
};
