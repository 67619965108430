import { productTiersApi } from "@portal-enroll/api/productTiersApi";
import { ProductTiersQueryKeys } from "@portal-enroll/queryKeys/productTiersQueryKeys";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import { useQuery } from "@tanstack/react-query";

export const useSignUpProductTiersQuery = () => {
  return useQuery({
    queryFn: productTiersApi.list,
    queryKey: ProductTiersQueryKeys.list(),
    select: (data) => data.map((tier) => new ProductTier(tier)),
    structuralSharing: false,
  });
};
