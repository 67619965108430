import { UUIDType } from "@common/types/apiTypes";
import { atom } from "jotai";

interface MultipleTdsp {
  prospectUUID: UUIDType;
  zipCode: string;
}

export interface EnrollModalsAtomType {
  areaNotServiceable: true | null;
  multipleSignUpFlowTabs: true | null;
  multipleTdsp: MultipleTdsp | null;
}

export const initialEnrollModalsAtom: EnrollModalsAtomType = {
  areaNotServiceable: null,
  multipleSignUpFlowTabs: null,
  multipleTdsp: null,
};

export const enrollModalsStateAtom = atom<EnrollModalsAtomType>(
  initialEnrollModalsAtom
);
