import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { SignUpEnrollmentFields } from "@portal-enroll/pages/SignUpEnrollmentPage/SignUpEnrollmentFields";
import { signUpEnrollmentFormValidator } from "@portal-enroll/pages/SignUpEnrollmentPage/signUpEnrollmentFormValidator";
import { SignUpEnrollmentFormValues } from "@portal-enroll/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";
import { signUpEnrollmentPageTranslations } from "@portal-enroll/pages/SignUpEnrollmentPage/SignUpEnrollmentPage.en.i18n";
import { SignUpStoreState } from "@portal-enroll/slices/signUpSlice";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

const initialValues: Partial<SignUpEnrollmentFormValues> = {
  enrollmentType: null,
};

export const SignUpEnrollmentPage = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { featureFlagClient } = useFeatureFlagClient();
  const currentValues = useSelector<
    SignUpStoreState,
    Partial<SignUpEnrollmentFormValues>
  >(({ signUp: { enrollmentType, serviceStartDate } }) => {
    const values: Partial<SignUpEnrollmentFormValues> = {
      enrollmentType,
    };
    // if no value, don't assign key so date field can initialize state itself

    if (serviceStartDate) {
      values.serviceStartDate = serviceStartDate;
    }

    return values;
  });

  const { translate } = useTranslations();

  const { tSignUpEnrollmentPageSubmitCTA, tSignUpEnrollmentPageHeader } =
    translate(signUpEnrollmentPageTranslations);

  const onSubmit = (values: SignUpEnrollmentFormValues) => {
    signUpClickNextStepHandler({
      nextStep: "billing-preferences",
      signUpData: values,
      track: true,
    });

    featureFlagClient.trackMetric(
      FeatureFlagMetric.PortalProspectMovingSwitchingSubmitted
    );
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerText={tSignUpEnrollmentPageHeader} />
      <Form<SignUpEnrollmentFormValues>
        initialValues={{ ...initialValues, ...currentValues }}
        onSubmit={onSubmit}
        validate={signUpEnrollmentFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpEnrollmentPageSubmitCTA}
          >
            <SignUpEnrollmentFields trackEvent={trackEvent} />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
