import { useSetDjangoLanguageCookie } from "@portal-shared/hooks/useSetDjangoLanguageCookie";
import {
  useRedirectIfCsrOrOps,
  useSendCustomerLoginEvent,
} from "@portal/hooks/useGlobalLogic";
import { useRenewalReminderModal } from "@portal/hooks/useRenewalReminderModal";
import { useSwitchEBillToPaperlessModal } from "@portal/hooks/useSwitchEBillToPaperlessModal";
import { useSwitchPrintToPaperlessModal } from "@portal/hooks/useSwitchPrintToPaperlessModal";

export const GlobalLogic = () => {
  useSendCustomerLoginEvent();
  useRedirectIfCsrOrOps();
  useSetDjangoLanguageCookie();
  useSwitchEBillToPaperlessModal();
  useSwitchPrintToPaperlessModal();
  useRenewalReminderModal();

  return null;
};
