import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { DialogContent } from "@mui/material";
import { guestAddPaymentCardTranslations } from "@portal-guest/components/GuestAddPaymentCard/GuestAddPaymentCard.en.i18n";
import {
  StyledGuestAddPaymentCardContent,
  StyledGuestAddPaymentCardRoot,
} from "@portal-guest/components/GuestAddPaymentCard/GuestAddPaymentCard.styled";
import {
  ActionType,
  GuestEvents,
  track,
} from "@portal-guest/services/segment.service";
import { LoggedOutZuoraCardForm } from "@portal-shared/components/LoggedOutZuoraCardForm/LoggedOutZuoraCardForm";
import {
  PaymentMethodOptions,
  PaymentMethodOptionsType,
} from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useRef, useState } from "react";

interface GuestAddPaymentCardProps {
  onCardAdded(
    response: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ): void;
  showFlashSuccess?: boolean;
  showPaymentOptions?: boolean;
}

export const GuestAddPaymentCard = (props: GuestAddPaymentCardProps) => {
  const { onCardAdded, showFlashSuccess = true, showPaymentOptions } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const flash = useRhFlash();
  const { translate } = useTranslations();

  const {
    tGuestAddPaymentCardAddNewCard,
    tGuestAddPaymentCardNewCardAddedSuccessfully,
    tGuestAddPaymentCardOpenDialog,
  } = translate(guestAddPaymentCardTranslations);

  const paymentOptionsRef = useRef<PaymentMethodOptionsType>({
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  });

  return (
    <>
      <StyledGuestAddPaymentCardRoot
        onClick={() => {
          track({
            action: ActionType.click,
            event: GuestEvents.addNewCard,
            label: "Clicked add new credit or debit card",
          });
          setIsDialogOpen(true);
        }}
        role="region"
        aria-label={tGuestAddPaymentCardOpenDialog}
      >
        <RhInnerCard>
          <StyledGuestAddPaymentCardContent>
            <RhTypography variant="subtitle2" fontWeight={FontWeight.Semibold}>
              {tGuestAddPaymentCardAddNewCard}
            </RhTypography>
            <RightChevron />
          </StyledGuestAddPaymentCardContent>
        </RhInnerCard>
      </StyledGuestAddPaymentCardRoot>
      <RhDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        size="large"
      >
        <RhDialogTitle>
          <div data-testid="GuestAddPaymentCard__Dialog--Title">
            {tGuestAddPaymentCardAddNewCard}
          </div>
        </RhDialogTitle>
        <DialogContent>
          <LoggedOutZuoraCardForm
            onSuccess={(response) => {
              onCardAdded(response, paymentOptionsRef.current);
              setIsDialogOpen(false);

              showFlashSuccess &&
                flash.success(tGuestAddPaymentCardNewCardAddedSuccessfully);

              track({
                action: ActionType.click,
                event: GuestEvents.addNewCard,
                label: "Clicked add new credit or debit card",
              });
            }}
            onFailure={(errorMessage) => {
              setIsDialogOpen(false);
              flash.error(errorMessage);
            }}
          />
          <PaymentMethodOptions
            showPaymentOptions={showPaymentOptions}
            paymentOptionsRef={paymentOptionsRef.current}
          />
        </DialogContent>
      </RhDialog>
    </>
  );
};
