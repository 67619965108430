import { Grid } from "@mui/material";
import { StyledSignUpFieldsLayoutContainer } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import React, { Children, PropsWithChildren } from "react";

interface SignUpFieldsLayoutProps {
  dense?: boolean;
  noMargin?: boolean;
}

export const LoggedOutFieldsLayout = (
  props: PropsWithChildren<SignUpFieldsLayoutProps>
) => {
  const { dense = false, noMargin = false, children } = props;

  return (
    <StyledSignUpFieldsLayoutContainer $noMargin={noMargin}>
      <Grid container direction="column" wrap="nowrap" spacing={dense ? 0 : 4}>
        {Children.map(children, (child) => (
          <Grid item>{child}</Grid>
        ))}
      </Grid>
    </StyledSignUpFieldsLayoutContainer>
  );
};
