import { noop } from "@common/utils/genericFunctions";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { multipleSignUpFlowTabsModalTranslations } from "@portal-enroll/components/MultipleSignUpFlowTabsModal/MultipleSignUpFlowTabsModal.en.i18n";
import {
  StyledContainer,
  StyledMessage,
  StyledTitle,
  StyledWarningImage,
} from "@portal-enroll/components/MultipleSignUpFlowTabsModal/MultipleSignUpFlowTabsModal.styled";
import { ReactComponent as BullWithUnpluggedCable } from "@portal-enroll/images/BullWithUnpluggedCable.svg";
import { TrackingView } from "@portal-shared/components/TrackingView/TrackingView";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const MultipleSignUpFlowTabsModal = () => {
  const { translate } = useTranslations();

  const {
    tMultipleSignUpFlowTabsModalDescription,
    tMultipleSignUpFlowTabsModalTitle,
  } = translate(multipleSignUpFlowTabsModalTranslations);

  return (
    <RhModal
      disableBackgroundClose
      hideCloseButton
      ariaLabel={tMultipleSignUpFlowTabsModalDescription}
      ariaCloseLabel=""
      handleClose={noop}
      size={ModalWidths.md}
    >
      <TrackingView
        data={{
          event: "Multiple tabs modal being shown",
        }}
      />
      <StyledContainer>
        <StyledWarningImage>
          <BullWithUnpluggedCable />
        </StyledWarningImage>
        <StyledTitle
          variant="h2"
          color="textPrimary"
          fontWeight={FontWeight.Regular}
        >
          {tMultipleSignUpFlowTabsModalTitle}
        </StyledTitle>
        <StyledMessage
          variant="subtitle2"
          color="textPrimary"
          fontWeight={FontWeight.Regular}
        >
          {tMultipleSignUpFlowTabsModalDescription}
        </StyledMessage>
      </StyledContainer>
    </RhModal>
  );
};
