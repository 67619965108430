import { DUNS_DISPLAY_NAME_MAPPING } from "@common/constants/edi.constants";
import {
  formatCurrency,
  formatDollarsToCents,
} from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { Box } from "@mui/material";
import { offerChargesTranslations } from "@portal-enroll/components/OfferCharges/OfferCharges.en.i18n";
import { PriceTierSnapshotsSummary } from "@portal-enroll/components/PriceTierSnapshotsSummary/PriceTierSnapshotsSummary";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

interface OfferChargesProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferCharges = ({ offerSnapshot }: OfferChargesProps) => {
  const { translate, translateJsx } = useTranslations();

  const { tOfferChargesTitle } = translate(offerChargesTranslations);

  const { dunsNumber } = useSelector(selectSignUpState);
  const {
    formattedCurrencyBaseCharge,
    hasBaseChargeAmount,
    isAutoPayPaperlessDiscountOffer,
    isSolarBuybackPriceDifferential,
    isThermostatOffer,
    isTimeOfUse,
    price,
    rhythmKwhRate,
    solarCreditKwhRate,
    tdspKwhRate,
    tdspMonthlyCharge,
  } = offerSnapshot;

  const {
    tOfferChargesBaseCharge,
    tOfferChargesEnergyCharge,
    tOfferChargesRate,
    tOfferChargesRatePaperless,
    tOfferChargesRatePaperlessAndThermostat,
    tOfferChargesRateThermostat,
    tOfferChargesSolarBuybackEnergyRate,
    tOfferChargesTdspDeliveryCharge,
    tOfferChargesTdspMonthlyCharge,
  } = translateJsx({
    tOfferChargesBaseCharge: {
      baseCharge: formattedCurrencyBaseCharge,
    },
    tOfferChargesEnergyCharge: {
      energyCharge: formatDollarsToCents(rhythmKwhRate),
    },
    tOfferChargesRate: {
      price: formatDollarsToCents(price),
    },
    tOfferChargesRatePaperless: {
      price: formatDollarsToCents(price),
    },
    tOfferChargesRatePaperlessAndThermostat: {
      price: formatDollarsToCents(price),
    },
    tOfferChargesRateThermostat: {
      price: formatDollarsToCents(price),
    },
    tOfferChargesSolarBuybackEnergyRate: {
      solarCreditKwhRate: formatDollarsToCents(solarCreditKwhRate),
    },
    tOfferChargesTdspDeliveryCharge: {
      charge: formatDollarsToCents(tdspKwhRate),
      tdsp: DUNS_DISPLAY_NAME_MAPPING[dunsNumber],
    },
    tOfferChargesTdspMonthlyCharge: {
      charge: formatCurrency(tdspMonthlyCharge),
      tdsp: DUNS_DISPLAY_NAME_MAPPING[dunsNumber],
    },
  });

  const averagePriceText = useCallback(() => {
    if (isThermostatOffer && isAutoPayPaperlessDiscountOffer) {
      return tOfferChargesRatePaperlessAndThermostat;
    }

    if (isThermostatOffer) {
      return tOfferChargesRateThermostat;
    }

    if (isAutoPayPaperlessDiscountOffer) {
      return tOfferChargesRatePaperless;
    }

    return tOfferChargesRate;
  }, [
    isAutoPayPaperlessDiscountOffer,
    isThermostatOffer,
    tOfferChargesRate,
    tOfferChargesRatePaperless,
    tOfferChargesRatePaperlessAndThermostat,
    tOfferChargesRateThermostat,
  ]);

  return (
    <div>
      <RhTypography variant="h3" fontWeight={FontWeight.Semibold}>
        {tOfferChargesTitle}
      </RhTypography>
      <Box mt={1}>
        {isTimeOfUse ? (
          <PriceTierSnapshotsSummary offerSnapshot={offerSnapshot} />
        ) : (
          <RhTypography component="p" variant="body1">
            {tOfferChargesEnergyCharge}
          </RhTypography>
        )}
        {isSolarBuybackPriceDifferential && (
          <RhTypography component="p" variant="body1">
            {tOfferChargesSolarBuybackEnergyRate}
          </RhTypography>
        )}
        {hasBaseChargeAmount && (
          <RhTypography component="p" variant="body1">
            {tOfferChargesBaseCharge}
          </RhTypography>
        )}
        <RhTypography component="p" variant="body1">
          {tOfferChargesTdspDeliveryCharge}
        </RhTypography>
        <RhTypography component="p" variant="body1">
          {tOfferChargesTdspMonthlyCharge}
        </RhTypography>
        <RhTypography
          component="p"
          variant="body1"
          fontWeight={FontWeight.Semibold}
        >
          {averagePriceText()}
        </RhTypography>
      </Box>
    </div>
  );
};
