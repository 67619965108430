import {
  EnrollModalsAtomType,
  enrollModalsStateAtom,
  initialEnrollModalsAtom,
} from "@portal-enroll/components/EnrollModalsManager/EnrollModalsManager.atoms";
import { useBaseModals } from "@portal-shared/hooks/useBaseModals";

export const useEnrollModals = () => {
  return useBaseModals<EnrollModalsAtomType>(
    enrollModalsStateAtom,
    initialEnrollModalsAtom
  );
};
