import { rhSpacingPx } from "@design-system/theme/spacing";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import styled from "styled-components";

export const StyledSkipPaymentContainer = styled.div`
  margin-top: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
  text-align: center;
`;

export const StyledZuoraContainer = styled.div`
  margin-top: ${rhSpacingPx(LOGGED_OUT_CONTAINER_SPACING)};
  width: ${LOGGED_OUT_FORM_WIDTH}px;
`;

export const StyledPaymentMethods = styled.div`
  display: flex;
  gap: ${rhSpacingPx(2)};
  justify-content: space-between;
`;
