import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { FormControlLabel } from "@mui/material";
import { RunCreditCheckType } from "@portal-enroll/pages/SignUpSSNRequiredPage/RunCreditCheckType.enum";
import { signUpSSNRequiredPageTranslations } from "@portal-enroll/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage.en.i18n";
import { SignUpRequiredRunCreditCheckRadioButton } from "@portal-enroll/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { useField } from "react-final-form";

interface RunCreditCheckRadioGroupProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const RunCreditCheckRadioGroup = ({
  onClick,
}: RunCreditCheckRadioGroupProps) => {
  const { translate } = useTranslations();
  const {
    tSignUpSSNRequiredPageRunCreditCheck,
    tSignUpSSNRequiredPageSkipCreditCheck,
  } = translate(signUpSSNRequiredPageTranslations);

  const { input: runCreditCheck } = useField<RunCreditCheckType>(
    "runCreditCheckType",
    {
      type: "radio",
      value: RunCreditCheckType.RunCheck,
    }
  );
  const { input: dontRunCreditCheck } = useField<RunCreditCheckType>(
    "runCreditCheckType",
    {
      type: "radio",
      value: RunCreditCheckType.SkipCheck,
    }
  );

  return (
    <SignUpRequiredRunCreditCheckRadioButton aria-label="run credit check">
      <FormControlLabel
        control={<RhRadioInput onClick={onClick} {...runCreditCheck} />}
        label={tSignUpSSNRequiredPageRunCreditCheck}
      />
      <FormControlLabel
        control={<RhRadioInput onClick={onClick} {...dontRunCreditCheck} />}
        label={tSignUpSSNRequiredPageSkipCreditCheck}
      />
    </SignUpRequiredRunCreditCheckRadioButton>
  );
};
