import { ZIPCODE_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { ReactComponent as MapPin } from "@design-system/icons/MapPin.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery } from "@mui/material";
import { updateZipCodeTranslations } from "@portal-enroll/components/UpdateZipCode/UpdateZipCode.en.i18n";
import {
  InputContainer,
  LabelText,
  StyledUpdateZipCodeButton,
  StyledUpdateZipCodeForm,
  StyledUpdateZipCodeInput,
  StyledUpdateZipCodeLabel,
} from "@portal-enroll/components/UpdateZipCode/UpdateZipCode.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

interface UpdateZipCodeFormValues {
  zipCode: string;
}

interface Props {
  onZipCodeSubmit: (zipCode: string) => void;
  zipCode: string;
}

export function UpdateZipCode({ onZipCodeSubmit, zipCode }: Props) {
  const { translate } = useTranslations();

  const isMobile = useMediaQuery(`(max-width:${RhythmBreakpoints.SM}px)`);
  const {
    register,
    formState: { isValid, isSubmitting },
    handleSubmit,
    resetField,
  } = useForm<UpdateZipCodeFormValues>({
    defaultValues: {
      zipCode,
    },
  });
  const updateZipCodeProps = register("zipCode", {
    maxLength: 5,
    minLength: 5,
    pattern: ZIPCODE_VALIDATION_REGEX,
    required: true,
  });

  const { tUpdateZipCodePlansFor, tUpdateZipCodeUpdate } = translate(
    updateZipCodeTranslations
  );

  const handleUpdateZipCode = handleSubmit(({ zipCode: _zipCode }) => {
    onZipCodeSubmit(_zipCode);
  });
  const isDisabled = !isValid || isSubmitting;

  useEffect(() => {
    if (zipCode) {
      resetField("zipCode", { defaultValue: zipCode });
    }
  }, [zipCode]);

  return (
    <StyledUpdateZipCodeForm onSubmit={handleUpdateZipCode}>
      <StyledUpdateZipCodeLabel>
        <MapPin aria-hidden />
        <LabelText>{tUpdateZipCodePlansFor}</LabelText>
        <InputContainer>
          <StyledUpdateZipCodeInput
            {...updateZipCodeProps}
            id="updateZipCodeInput"
            placeholder="12345"
            // disable 1password as it annoys devs
            data-1p-ignore
          />
        </InputContainer>
      </StyledUpdateZipCodeLabel>

      <StyledUpdateZipCodeButton
        data-tracking-click={{ event: "Prospect updating zip code" }}
        color="primary"
        size="small"
        variant={isMobile ? "text" : "contained"}
        disabled={isDisabled}
        type="submit"
      >
        {tUpdateZipCodeUpdate}
      </StyledUpdateZipCodeButton>
    </StyledUpdateZipCodeForm>
  );
}
