import "jotai-devtools/styles.css";

import { ReactQueryDevTools } from "@common/components/ReactQueryDevTools";
import { Version } from "@common/components/Version/Version";
import { useModalState } from "@common/hooks/useModalState";
import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import { PortalDevTool } from "@portal-shared/components/PortalDevTool/PortalDevTool";
import { DevTools as JotaiDevTools } from "jotai-devtools";
import React from "react";

export const PortalDevTools = () => {
  const { isOpen, open, close } = useModalState(false);

  return (
    <>
      <Version handleClick={open} />
      <ReactQueryDevTools />
      <JotaiDevTools />

      {isOpen && !isProduction() ? <PortalDevTool handleClose={close} /> : null}
    </>
  );
};
