import { formatCurrency } from "@common/utils/dataFormatters";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhRadio } from "@design-system/components/RhRadio/RhRadio";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Box, RadioGroup } from "@mui/material";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpDepositRequiredPageTranslations } from "@portal-enroll/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage.en.i18n";
import { ActionType } from "@portal-enroll/services/segment.service";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutZuoraCardForm } from "@portal-shared/components/LoggedOutZuoraCardForm/LoggedOutZuoraCardForm";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React, { useState } from "react";
import styled from "styled-components";

interface DepositSelectionFormProps {
  depositAlternativeAmount: string | null;
  depositAmount: number | null;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin-top: unset;
    padding: 40px;
    width: 70%;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 40px;
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    width: 75%;
  }
`;

export const DepositSelectionForm = (props: DepositSelectionFormProps) => {
  const { depositAmount, depositAlternativeAmount } = props;
  const flash = useRhFlash();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();

  const [selectedDepositType, setSelectedDepositType] = useState<
    "deposit" | "depositAlternative"
  >();

  const { translate, translateJsx } = useTranslations();

  const {
    tSignUpDepositRequiredPageCouldBe,
    tSignUpDepositRequiredPageSorryWeNeedADepositV2,
    tSignUpDepositRequiredPageTwoDepositOptions,
  } = translate(signUpDepositRequiredPageTranslations);

  const {
    tSignUpDepositRequiredPageDepositAlternativeAmount,
    tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit,
    tSignUpDepositRequiredPageDepositAmount,
  } = translateJsx({
    tSignUpDepositRequiredPageDepositAlternativeAmount: {
      bold: (text: string) => <strong>{text}</strong>,
      depositAlternativeAmount: formatCurrency(depositAlternativeAmount ?? 0),
    },
    tSignUpDepositRequiredPageDepositAmount: {
      bold: (text: string) => <strong>{text}</strong>,
      depositAmount: formatCurrency(depositAmount ?? 0),
    },
    tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit: {
      link: (faqPage: string) => (
        <RhAnchor
          href={brandInformation.urlsFaqsSatisfyDeposit}
          target="_blank"
          onClick={() =>
            trackEvent({ action: ActionType.click, label: "depositFaqSite" })
          }
        >
          {faqPage}
        </RhAnchor>
      ),
    },
  });

  return (
    <>
      <LoggedOutPageHeader
        headerText={tSignUpDepositRequiredPageSorryWeNeedADepositV2}
      />
      <StyledContainer>
        <RhTypography variant="subtitle2" align="center" color="textSecondary">
          {tSignUpDepositRequiredPageCouldBe}
        </RhTypography>

        <RhTypography variant="subtitle2" align="center" color="textSecondary">
          {tSignUpDepositRequiredPageTwoDepositOptions}
        </RhTypography>
        <StyledRadioGroup row aria-label="Deposit Selection">
          <RhRadio
            value="deposit"
            name="depositSelection"
            handleChange={() => setSelectedDepositType("deposit")}
          >
            <RhTypography variant="body1">
              {tSignUpDepositRequiredPageDepositAmount}
            </RhTypography>
          </RhRadio>
          <RhRadio
            value="depositAlternative"
            name="depositSelection"
            handleChange={() => setSelectedDepositType("depositAlternative")}
          >
            <RhTypography variant="body1">
              {tSignUpDepositRequiredPageDepositAlternativeAmount}
            </RhTypography>
          </RhRadio>
        </StyledRadioGroup>
        <Box paddingBottom={LOGGED_OUT_CONTAINER_SPACING} textAlign="center">
          <RhTypography variant="subtitle2" color="textSecondary">
            {tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit}
          </RhTypography>
        </Box>
      </StyledContainer>

      {selectedDepositType ? (
        <LoggedOutZuoraCardForm
          onSuccess={({ refId }) => {
            signUpClickNextStepHandler({
              nextStep: "summary",
              signUpData: {
                billingPaymentMethodId: refId,
                depositAlternativeElected:
                  selectedDepositType === "depositAlternative",
              },
              track: true,
            });
          }}
          onFailure={flash.error}
        />
      ) : null}
    </>
  );
};
