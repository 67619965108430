import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import {
  enrollBasePath,
  signUpBasePath,
} from "@enroll-utils/constants/routePaths";
import { useEnrollModals } from "@portal-enroll/components/EnrollModalsManager/useEnrollModals";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";

const isSignUpFlow = (pathname: string) => {
  return (
    pathname.startsWith(signUpBasePath()) ||
    pathname.startsWith(enrollBasePath())
  );
};

const SIGN_UP_FLOW_MULTIPLE_TABS_CHANNEL = "sign-up-flow-multiple-tabs-channel";

const tabId = uuidV4();

enum TabState {
  InSignUpFlow = "Tab in sign up flow",
  NotInSignUpFlow = "Tab not in sign up flow",
}

enum MessageType {
  NotifyOtherTabsWhetherInSignUpFlow = "NotifyOtherTabsWhetherInSignUpFlow",
  OtherTabsNotifyThisTabIfYouAreInSignUpFlow = "OtherTabsNotifyThisTabIfYouAreInSignUpFlow",
}

type Message =
  | {
      type: MessageType.OtherTabsNotifyThisTabIfYouAreInSignUpFlow;
    }
  | {
      payload: [string, TabState];
      type: MessageType.NotifyOtherTabsWhetherInSignUpFlow;
    };

const channel: BroadcastChannel | undefined = // eslint-disable-next-line compat/compat
  BroadcastChannel && new BroadcastChannel(SIGN_UP_FLOW_MULTIPLE_TABS_CHANNEL);

export const useDetectMultipleSignUpFlowTabs = () => {
  const { pathname } = useLocation();
  const { addModal, removeModal } = useEnrollModals();
  const [tabsInSignUpFlow] = useState<Set<string>>(new Set<string>());
  const [numTabsInSignUpFlow, setNumTabsInSignUpFlow] = useState<number>(0);

  useEffect(() => {
    if (!channel) {
      return;
    }

    channel.postMessage({
      type: MessageType.OtherTabsNotifyThisTabIfYouAreInSignUpFlow,
    });

    // Broadcast message if tab closes
    const onUnload = () => {
      const message: Message = {
        payload: [tabId, TabState.NotInSignUpFlow],
        type: MessageType.NotifyOtherTabsWhetherInSignUpFlow,
      };

      channel.postMessage(message);
    };

    // when this tab closes, notify other tabs this tab is not in the sign up flow
    window.addEventListener("unload", onUnload);

    return () => {
      window.removeEventListener("unload", onUnload);
    };
  }, []);

  // Broadcast message if app is is sign up flow
  useEffect(() => {
    if (!channel) {
      return;
    }

    if (isSignUpFlow(pathname)) {
      channel.postMessage({
        payload: [tabId, TabState.InSignUpFlow],
        type: MessageType.NotifyOtherTabsWhetherInSignUpFlow,
      });
    }
  }, [pathname]);

  // add or remove this tab depending on whether is is sign up flow
  useEffect(() => {
    if (!channel) {
      return;
    }

    const addOrRemoveThisTabWhetherInSignUpFlow = () => {
      if (isSignUpFlow(pathname)) {
        tabsInSignUpFlow.add(tabId);
      } else {
        tabsInSignUpFlow.delete(tabId);
      }

      setNumTabsInSignUpFlow(tabsInSignUpFlow.size);
    };

    const listenToMessagesFromOtherTabs = (event: MessageEvent<Message>) => {
      // Other tabs are notifying this tab if they are in the sign up flow
      if (event.data.type === MessageType.NotifyOtherTabsWhetherInSignUpFlow) {
        const [otherTabId, tabState] = event.data.payload;

        if (tabState === TabState.InSignUpFlow) {
          tabsInSignUpFlow.add(otherTabId);
        } else if (tabState === TabState.NotInSignUpFlow) {
          tabsInSignUpFlow.delete(otherTabId);
        }
        setNumTabsInSignUpFlow(tabsInSignUpFlow.size);
        return;
      }

      // Other tabs are asking this tab to notify them about whether this tab is in sign up flow
      if (
        event.data.type ===
          MessageType.OtherTabsNotifyThisTabIfYouAreInSignUpFlow &&
        isSignUpFlow(pathname)
      ) {
        const message: Message = {
          payload: [tabId, TabState.InSignUpFlow],
          type: MessageType.NotifyOtherTabsWhetherInSignUpFlow,
        };

        channel.postMessage(message);
      }
    };

    addOrRemoveThisTabWhetherInSignUpFlow();
    channel.addEventListener("message", listenToMessagesFromOtherTabs);

    return () => {
      channel.removeEventListener("message", listenToMessagesFromOtherTabs);
    };
  }, [pathname, tabsInSignUpFlow]);

  useEffect(() => {
    if (numTabsInSignUpFlow > 1 && isProduction()) {
      addModal({
        multipleSignUpFlowTabs: true,
      });
    }
    if (numTabsInSignUpFlow <= 1) {
      removeModal("multipleSignUpFlowTabs");
    }
  }, [addModal, numTabsInSignUpFlow, removeModal]);
};
