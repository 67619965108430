import { GuestFeatureFlagClientProvider } from "@portal-guest/components/GuestFeatureFlagClientProvider/GuestFeatureFlagClientProvider";
import { GuestGlobalLogic } from "@portal-guest/components/GuestGlobalLogic/GuestGlobalLogic";
import { GuestHeader } from "@portal-guest/components/GuestHeader/GuestHeader";
import { AwsConnectHelmet } from "@portal-shared/components/AwsConnectHelmet/AwsConnectHelmet";
import { LoggedOutFooter } from "@portal-shared/components/LoggedOutFooter/LoggedOutFooter";
import { PortalContent } from "@portal-shared/components/PortalContent/PortalContent.styled";
import { PortalSharedMainContainer } from "@portal-shared/components/PortalSharedMainContainer/PortalSharedMainContainer";
import { SharedModalsManager } from "@portal-shared/components/SharedModalsManager/SharedModalsManager";
import React from "react";
import { Outlet } from "react-router-dom";

export const GuestRouteProvidersWithOutlet = () => {
  return (
    <GuestFeatureFlagClientProvider>
      <GuestGlobalLogic />
      <SharedModalsManager />
      <AwsConnectHelmet />
      <PortalSharedMainContainer>
        <GuestHeader />
        <PortalContent>
          <Outlet />
        </PortalContent>
        <LoggedOutFooter />
      </PortalSharedMainContainer>
    </GuestFeatureFlagClientProvider>
  );
};
