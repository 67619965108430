import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import {
  ActionType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useFingerPrintIdentify = () => {
  const { fingerprintId } = useSelector(selectSignUpState);

  useEffect(() => {
    if (fingerprintId) {
      track({
        action: ActionType.userIdentified,
        category: "fingerprint",
        event: EnrollmentEvents.identify,
        label: "Fingerprint User Identified",
        value: fingerprintId,
      });
    }
  }, [fingerprintId]);
};
