import { noop } from "@common/utils/genericFunctions";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { multipleTdspModalTranslations } from "@portal-enroll/components/MultipleTdspModal/MultipleTdspModal.en.i18n";
import {
  MultipleTdspModalForm,
  MultipleTdspModalLabel,
  StyledSubheaderContainer,
  StyledSubtitleContainer,
} from "@portal-enroll/components/MultipleTdspModal/MultipleTdspModal.styled";
import { useSearchUtilitiesQuery } from "@portal-enroll/queries/useSearchUtilitiesQuery";
import { PortalRadioButton } from "@portal-shared/components/PortalRadioButton/PortalRadioButton";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

interface UtilityInput {
  dunsNumber: string;
}

interface MultipleTdspProps {
  handleClose: () => void;
  zipCode: string;
}

export const MultipleTdspModal = (props: MultipleTdspProps) => {
  const { handleClose, zipCode } = props;
  const { translate } = useTranslations();
  const searchUtilitiesQuery = useSearchUtilitiesQuery({
    searchOptions: { zipCode },
  });
  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting },
  } = useForm<UtilityInput>();
  const prospectUpdateMutation = useProspectUpdateMutation();

  const {
    tMultipleTdspModalNotSureWhichUtility,
    tMultipleTdspModalSelectUtility,
    tMultipleTdspModalSubmit,
  } = translate(multipleTdspModalTranslations);

  const onSubmit: SubmitHandler<UtilityInput> = ({ dunsNumber }) => {
    prospectUpdateMutation.mutate(
      {
        data: {
          dunsNumber,
        },
      },
      {
        onSettled: () => {
          handleClose();
        },
      }
    );
  };

  if (searchUtilitiesQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (searchUtilitiesQuery.isError) {
    handleClose();
    return null;
  }

  return (
    <RhModal
      disableBackgroundClose
      hideCloseButton
      ariaLabel=""
      ariaCloseLabel=""
      handleClose={noop}
      size={ModalWidths.md}
    >
      <RhTypography variant="h1" align="center">
        {tMultipleTdspModalSelectUtility}
      </RhTypography>

      <StyledSubheaderContainer>
        <StyledSubtitleContainer variant="subtitle2" align="center">
          {tMultipleTdspModalNotSureWhichUtility}
        </StyledSubtitleContainer>
      </StyledSubheaderContainer>

      <MultipleTdspModalForm onSubmit={handleSubmit(onSubmit)}>
        {searchUtilitiesQuery.data.map(({ dunsNumber, name }) => {
          return (
            <MultipleTdspModalLabel key={dunsNumber}>
              <PortalRadioButton
                value={dunsNumber}
                type="radio"
                {...register("dunsNumber", { required: true })}
              />
              {name}
            </MultipleTdspModalLabel>
          );
        })}

        <RhButton
          data-tracking-click={{
            event: "Prospect selected TDSP",
          }}
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isValid || isSubmitting}
        >
          {tMultipleTdspModalSubmit}
        </RhButton>
      </MultipleTdspModalForm>
    </RhModal>
  );
};
