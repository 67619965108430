export const loggedOutZuoraCardFormTranslations: Record<string, string> = {
  tLoggedOutZuoraCardFormAuthorizationFailed:
    "We're having trouble connecting to the billing service. Please try again.",
  tLoggedOutZuoraCardFormErrorAddingCard:
    "Unable to add card. Please verify your card information and try again.",
  "tLoggedOutZuoraCardFormInlineFormError.expirationDateInvalid":
    "Please enter a valid expiration date.",
  "tLoggedOutZuoraCardFormInlineFormError.expiredCard":
    "Your card is expired. Please add a different one or correct your information.",
  "tLoggedOutZuoraCardFormInlineFormError.fieldEmpty":
    "Please complete all form fields.",
  "tLoggedOutZuoraCardFormInlineFormError.genericDecline":
    "Your card was declined. Please try a different one.",
  "tLoggedOutZuoraCardFormInlineFormError.insufficientFunds":
    "Your card was declined because of insufficient funds. Please try a different one or contact your bank.",
  "tLoggedOutZuoraCardFormInlineFormError.lostCard":
    "Your card was declined. Please try a different one.",
  "tLoggedOutZuoraCardFormInlineFormError.nameInvalid":
    "Name must match the name on the card.",
  "tLoggedOutZuoraCardFormInlineFormError.numberInvalid":
    "Invalid card number. Please try again.",
  "tLoggedOutZuoraCardFormInlineFormError.securityCodeInvalid":
    "Please enter a valid security code.",
  "tLoggedOutZuoraCardFormInlineFormError.stolenCard":
    "Your card was declined. Please try a different one.",
  "tLoggedOutZuoraCardFormInlineFormError.tooManyTries":
    "You have tried too many times to enter a card with invalid information. Please try again.",
  "tLoggedOutZuoraCardFormInlineFormError.unknownError":
    "We were unable to add your card. Please try again.",
  "tLoggedOutZuoraCardFormInlineFormError.zipCodeInvalid":
    "Please enter a valid Zip Code.",
};
