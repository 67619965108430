/// <reference types="@types/segment-analytics" />
import { isBot } from "@common/utils/isBot";

export enum EnrollmentEvents {
  enrollmentClick = "enrollmentClick",
  identify = "identify",
}

export enum CategoryType {
  // Availability = "availability",
  BillingPreferences = "billing-preferences",
  Contact = "contact",
  FullStory = "fullstory",
  // MyAccountRenewals = "My Account Renewals",
  Name = "name",
  // OffersPage = "onboarding.plans",
  // OneClickRenewals = "One-click Renewals",
  Premise = "premise",
  // Renewals = "renewals",
}

export enum ActionType {
  billingPreferencesSubmitted = "billingPreferencesSubmitted",
  click = "click",
  clickedBackPage = "clickedBackPage",
  clickedEnrollmentSubmit = "clickedEnrollmentSubmit",
  clickedNextPage = "clickedNextPage",
  contactSubmitted = "contactSubmitted",
  emailSubmitted = "emailSubmitted",
  focus = "fieldFocus",
  nameSubmitted = "nameSubmitted",
  openedCart = "openedCart",
  premiseSubmitted = "premiseSubmitted",
  prospectCreated = "prospectCreated",
  userIdentified = "userIdentified",
}

export enum LabelType {
  // ActiveServiceArea = "Active Service Area",
  // ClickedEFL = "Clicked EFL",
  // ClickedOtherOffersToggle = "Clicked other offers toggle",
  // ClickedProductTierSeeMore = "Clicked product tier see-more button",
  // ClickedRenewalCompetitorChartSelectPlan = "Clicked Renewal Competitor Chart Select Plan",
  // ClickedRenewalOfferCardLearnMore = "Clicked Renewal Offer Card Learn More",
  // ClickedRenewalOfferCardRenewNow = "Clicked Renewal Offer Card Renew Now",
  // ClickedRenewalSubmit = "Clicked Renewal Submit",
  // ClickedRenewalsCta = "Clicked Renewals CTA",
  ConfirmedProductAddOnSelection = "Confirmed product add-on selection",
  // EnrollmentFromOffersPageToNextPage = "plansNextPage",
  FullStoryUserIdentified = "FullStory User Identified",
  // InvalidServiceArea = "Invalid Service Area",
  // OpenedCart = "Opened Cart",
  // SelectedProductTier = "Selected product tier",
  SkippedProductAddOnsSelection = "Skipped product add-ons selection",
}

export interface AnalyticsEvent {
  [key: string]: string | number | boolean;
  action: ActionType;
  category: string;
  event: EnrollmentEvents;
  label: string;
  value: string | boolean;
}

export type TrackEventHandler = (event: Partial<AnalyticsEvent>) => void;

export const track = (options: Partial<AnalyticsEvent>) => {
  if (isBot()) {
    return;
  }

  const { event, ...properties } = options;

  if (event) {
    window.FS?.event(event, properties);
    window.analytics?.track?.(event, properties);
    window.dataLayer?.push(options);
  }
};

export const createTrackEvent = (
  base: Partial<AnalyticsEvent>
): TrackEventHandler => {
  return (options: Partial<AnalyticsEvent>) => {
    track({ ...base, ...options });
  };
};
