import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import { StyledNavIconAnchor } from "@design-system/components/RhHeader/RhHeader.styled";
import { ReactComponent as PhoneIcon } from "@design-system/icons/PhoneIcon.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { CartDesktop } from "@portal-enroll/components/CartDesktop/CartDesktop";
import { EnrollHeaderLogo } from "@portal-enroll/components/EnrollHeaderLogo/EnrollHeaderLogo";
import { useIsCartSignUpStep } from "@portal-enroll/hooks/useIsCartSignUpStep";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { ActionType } from "@portal-enroll/services/segment.service";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";
import { useSelector } from "react-redux";

const rhHeaderItems: JSX.Element[] = [];

export const EnrollHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const isCartStep = useIsCartSignUpStep();
  const { trackEvent } = useSignUpFlow();
  const {
    acquisition: { acquisitionTfn },
  } = useSelector(selectSignUpState);

  const onPhoneNumberClick = () => {
    trackEvent({
      action: ActionType.click,
      category: "page.top-nav",
      label: "phoneNumber",
      value: acquisitionTfn || brandInformation.phoneNumbersGeneralSales,
    });
  };

  const cart = isCartStep && !isMobile ? <CartDesktop /> : null;

  const phoneNumber = acquisitionTfn ? (
    <StyledNavIconAnchor
      key="phoneNumber"
      href={`tel:${acquisitionTfn}`}
      onClick={onPhoneNumberClick}
    >
      <PhoneIcon />
      {acquisitionTfn}
    </StyledNavIconAnchor>
  ) : null;

  return (
    <RhHeader
      items={rhHeaderItems}
      logo={<EnrollHeaderLogo />}
      phone={phoneNumber}
      cart={cart}
    />
  );
};
