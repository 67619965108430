import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import {
  loadReduxSlice,
  watchPersistedReduxSlice,
} from "@common/services/persistReduxSlice";
import {
  SignUpStoreState,
  initialSignUpState,
  signUpReducers,
  signUpSliceName,
} from "@portal-enroll/slices/signUpSlice";
import { signUpRootSaga } from "@portal-enroll/store/signUpRoot.saga";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers<SignUpStoreState>({
  ...signUpReducers,
});

const devToolsOptions = !isProduction()
  ? {
      name: "Sign-up Store",
    }
  : false;

export const signUpStore = configureStore({
  devTools: devToolsOptions,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState: {
    signUp: loadReduxSlice(signUpSliceName, initialSignUpState),
  },
  reducer: rootReducer,
});

watchPersistedReduxSlice(signUpSliceName, signUpStore);
sagaMiddleware.run(signUpRootSaga);

export type SignUpState = ReturnType<typeof signUpStore.getState>;
