import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { SignUpProductTiers } from "@portal-enroll/components/SignUpProductTiers/SignUpProductTiers";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpProductTiersPageTranslations } from "@portal-enroll/pages/SignUpProductTiersPage/SignUpProductTiersPage.en.i18n";
import {
  StyledButton,
  StyledLayout,
} from "@portal-enroll/pages/SignUpProductTiersPage/SignUpProductTiersPage.styled";
import { ActionType, LabelType } from "@portal-enroll/services/segment.service";
import { signUpSlice } from "@portal-enroll/slices/signUpSlice";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

export const SignUpProductTiersPage = () => {
  const dispatch = useDispatch();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();

  const { translate } = useTranslations();
  const {
    tSignUpProductTiersPageNoThanksButton,
    tSignUpProductTiersPagePageSubTitle,
    tSignUpProductTiersPagePageTitle,
    tSignUpProductTiersPageSkipButton,
  } = translate(signUpProductTiersPageTranslations);

  const handleSkip = useCallback(
    (buttonId: string) => {
      dispatch(signUpSlice.actions.clearProductPrices());

      signUpClickNextStepHandler({
        nextStep: "summary",
        signUpData: {
          productPrices: [],
        },
        track: false,
      });

      trackEvent({
        action: ActionType.clickedNextPage,
        buttonId,
        label: LabelType.SkippedProductAddOnsSelection,
      });
    },
    [signUpClickNextStepHandler, trackEvent, dispatch]
  );

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpProductTiersPagePageTitle}
        subHeaderText={tSignUpProductTiersPagePageSubTitle}
      />

      <StyledLayout>
        <StyledButton
          data-tracking-click={{
            event: "Prospect opting out of any product add on",
          }}
          variant="text"
          color="primary"
          onClick={() => handleSkip("button-no-thanks")}
        >
          {tSignUpProductTiersPageNoThanksButton}{" "}
          <ChevronRightIcon aria-hidden="true" />
        </StyledButton>

        <SignUpProductTiers />

        <StyledButton
          data-tracking-click={{
            event: "Prospect skipping adding a product addon",
          }}
          variant="text"
          color="primary"
          onClick={() => handleSkip("button-skip")}
        >
          {tSignUpProductTiersPageSkipButton}{" "}
          <ChevronRightIcon aria-hidden="true" />
        </StyledButton>
      </StyledLayout>
    </SignUpPageLayout>
  );
};
