import { ReactComponent as StepperCheckIcon } from "@design-system/icons/StepperCheckIcon.svg";
import { ReactComponent as StepperFourIcon } from "@design-system/icons/StepperFourIcon.svg";
import { ReactComponent as StepperOneIcon } from "@design-system/icons/StepperOneIcon.svg";
import { ReactComponent as StepperThreeIcon } from "@design-system/icons/StepperThreeIcon.svg";
import { ReactComponent as StepperTwoIcon } from "@design-system/icons/StepperTwoIcon.svg";
import { SignUpStepperKeyType } from "@portal-enroll/components/SignUpStepper/signUpStepperTypes";

export const signUpStepperIconMapping: Record<
  SignUpStepperKeyType | "completed",
  typeof StepperOneIcon
> = {
  completed: StepperCheckIcon,
  contact: StepperTwoIcon,
  payment: StepperThreeIcon,
  plans: StepperOneIcon,
  summary: StepperFourIcon,
};
