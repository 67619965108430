import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { formatMaskNumber } from "@common/forms/formatters";
import {
  PaymentMethodRetrieveType,
  PaymentMethodTypes,
} from "@enroll-utils/types/paymentMethodTypes";
import { signUpSummaryInfoTranslations } from "@portal-enroll/components/SignUpSummaryInfo/SignUpSummaryInfo.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useEffect, useState } from "react";

interface PaymentDetailsHookResult {
  paymentMethod: string;
  paymentMethodAccountNumberMask: string | null;
}

export const useSignUpSummaryPaymentDetails = (
  paymentMethodDetails: PaymentMethodRetrieveType | undefined
) => {
  const [paymentDetails, setPaymentDetails] =
    useState<PaymentDetailsHookResult>({
      paymentMethod: NOT_APPLICABLE_ABBREVIATION,
      paymentMethodAccountNumberMask: null,
    });

  const { translate } = useTranslations();

  const { tSignUpSummaryInfoCreditCard } = translate(
    signUpSummaryInfoTranslations
  );

  useEffect(() => {
    let paymentMethod = NOT_APPLICABLE_ABBREVIATION;
    let paymentMethodAccountNumberMask: string | null = null;

    if (paymentMethodDetails) {
      paymentMethod =
        paymentMethodDetails.type === PaymentMethodTypes.CreditCard
          ? tSignUpSummaryInfoCreditCard
          : paymentMethodDetails.achBankName;

      const maskNumber =
        paymentMethodDetails.type === PaymentMethodTypes.CreditCard
          ? paymentMethodDetails.creditCardMaskNumber
          : paymentMethodDetails.achAccountNumberMask;

      paymentMethodAccountNumberMask = formatMaskNumber(maskNumber);
    }

    setPaymentDetails({
      paymentMethod,
      paymentMethodAccountNumberMask,
    });
  }, [paymentMethodDetails, tSignUpSummaryInfoCreditCard]);

  return paymentDetails;
};
